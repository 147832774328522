import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import url from "../data/url.json";

export const CustomersContext = createContext();

export const Provider = (props) => {
  const [customers, setCustomers] = useState();

  useEffect(() => {
    getCustomersList();
  }, []);

  const getCustomersList = async () => {
    try {
      const response = await axios.get(`${url}/api/customers`);

      setCustomers(response.data);
    } catch (error) {
      console.log("Retreiving Customers List Error: ", error);
    }
  };

  const addCustomer = async (newCustomer) => {
    try {
      await axios.post(`${url}/api/customers`, newCustomer);

      getCustomersList();
    } catch (error) {
      console.log("Adding Customer Error :", error);
      return error;
    }
  };

  const getCustomer = async (customerId) => {
    try {
      const response = await axios.get(`${url}/api/customers/${customerId}`);
      return response.data;
    } catch (error) {
      console.log("Retreiving Customer Error: ", error);
    }
  };

  const updateCustomer = async (customerId, updatedCustomer) => {
    try {
      const response = await axios.put(
        `${url}/api/customers/${customerId}`,
        updatedCustomer
      );
      return response.data;
    } catch (error) {
      console.log("Updating Customer Error: ", error);
    }
  };

  const deleteCustomer = async (customerId) => {
    try {
      await axios.delete(`${url}/api/customers/${customerId}`);

      getCustomersList();
    } catch (error) {
      console.log("Deleting Customer Error: ", error);
      return error;
    }
  };

  return (
    <CustomersContext.Provider
      value={{
        customers,
        action: {
          addCustomer,
          deleteCustomer,
          getCustomer,
          getCustomersList,
          setCustomers,
          updateCustomer,
          getCustomersList,
        },
      }}
    >
      {props.children}
    </CustomersContext.Provider>
  );
};
