import React, { useContext, useEffect, useState } from "react";
import { Spinner, Tab, Tabs } from "react-bootstrap";
import Moment from "moment";

import { SalesContext } from "../../context/salesContext";
import { CustomersContext } from "../../context/customersContext";

import "../main/Dashboard.css";

const Dashboard = () => {
  const [key, setKey] = useState("work-overview");
  const { salesList } = useContext(SalesContext);
  const { customers } = useContext(CustomersContext);

  const ready =
    salesList &&
    salesList.filter(
      (sale) =>
        sale.workOrder.status === "Ready For Installation" ||
        sale.workOrder.status === "Ready For Pick Up"
    );

  const doors =
    salesList &&
    salesList.filter(
      (sale) =>
        sale.workOrder.status === "Prep" ||
        sale.workOrder.status === "Making Door"
    );

  const pending =
    salesList &&
    salesList.filter((sale) => sale.workOrder.status.includes("Pending"));

  const overdue =
    salesList &&
    salesList
      .filter((sale) => {
        let expectedDate = Moment(sale.expectedCompletionDate).startOf("day");
        let todaysDate = Moment().startOf("day");

        return expectedDate.diff(todaysDate, "days") < 0;
      })

      .filter((sale) => !sale.workOrder.status.includes("Completed"))
      .filter((sale) => !sale.workOrder.status.includes("Ready"));

  return (
    <div className="Dashboard">
      {salesList ? (
        <div>
          <div className="Dashboard-overview-section">
            <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k) => setKey(k)}
            >
              <Tab eventKey="work-overview" title="Work Order Overview">
                {salesList && (
                  <div className="Dashboard-overview-card-container">
                    <div className="Dashboard-overview-card">
                      <i
                        className="fas fa-exclamation-circle"
                        style={{
                          padding: "0.3rem",
                          border: "1px solid #FFA0A0",
                          borderRadius: "50%",
                          color: "red",
                          backgroundColor: "#FFA0A0",
                        }}
                      ></i>
                      <h1>{overdue.length}</h1>
                      <p style={{ color: "red" }}>Overdue Jobs</p>
                    </div>
                    <div className="Dashboard-overview-card">
                      <i
                        className="fas fa-thumbs-up"
                        style={{
                          padding: "0.3rem",
                          border: "1px solid #C1FF9D",
                          borderRadius: "50%",
                          color: "rgb(0, 207, 0)",
                        }}
                      ></i>
                      <h1>{ready.length}</h1>
                      <p style={{ color: "rgb(0, 207, 0)" }}>Ready</p>
                    </div>
                    <div className="Dashboard-overview-card">
                      <i
                        className="fas fa-tasks"
                        style={{
                          padding: "0.3rem",
                          border: "1px solid #91D2FF",
                          borderRadius: "50%",
                          color: "rgb(28, 115, 255)",
                          backgroundColor: "#91D2FF",
                        }}
                      ></i>
                      <h1>{doors.length}</h1>
                      <p style={{ color: "rgb(28, 115, 255)" }}>Doors</p>
                    </div>
                    <div className="Dashboard-overview-card">
                      <i
                        className="fas fa-ellipsis-h"
                        style={{
                          padding: "0.3rem",
                          border: "1px solid #FFFD78",
                          borderRadius: "50%",
                          color: "#ebe424",
                          backgroundColor: "#FFFD78",
                        }}
                      ></i>
                      <h1>{pending.length}</h1>
                      <p style={{ color: "#ebe424" }}>Pending</p>
                    </div>
                  </div>
                )}
              </Tab>
              <Tab eventKey="sales-overview" title="Sales Overview">
                <div className="Dashboard-overview-card-container">
                  <div className="Dashboard-overview-card">
                    Products Low on Inventory
                  </div>
                  <div className="Dashboard-overview-card">
                    Sales from custoemr/contractor
                  </div>
                  <div className="Dashboard-overview-card">Gross Profit</div>
                  <div className="Dashboard-overview-card">
                    Monthly Sales Sold Glass/Sold Doors
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
          <div className="Dashboard-calendar-section">
            <div className="Dashboard-calendar-container">
              <div className="Dashboard-calendar-header">
                <h4 className="title">Coming Up</h4>
                <p>View All</p>
              </div>
              <table className="Dashboard-calendar-table">
                <thead>
                  <tr>
                    <th>
                      <h6>Job</h6>
                    </th>
                    <th>
                      <h6>Expected Completion</h6>
                    </th>
                    <th>
                      <h6>Status</h6>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {salesList &&
                    customers &&
                    customers.length > 0 &&
                    salesList
                      .filter((sale) => sale.workOrder.status !== "Completed")
                      .filter(
                        (sale) => sale.workOrder.status !== "Ready For Pick Up"
                      )
                      .filter(
                        (sale) =>
                          sale.workOrder.status !== "Ready For Installation"
                      )
                      .slice()
                      .sort(
                        (a, b) =>
                          new Date(a.expectedCompletionDate) -
                          new Date(b.expectedCompletionDate)
                      )
                      .map((sale, index) => {
                        const customer = customers.filter(
                          (customer) => customer._id === sale.customerId
                        );

                        // Calc pending days
                        const getPendingDays = () => {
                          let expectedCompletionDate = Moment(
                            sale.expectedCompletionDate
                          ).startOf("day");

                          let todaysDate = Moment().startOf("day");

                          let pendingDays = expectedCompletionDate.diff(
                            todaysDate,
                            "days"
                          );

                          if (parseInt(pendingDays) < 0) {
                            return (
                              <p style={{ color: "red", fontWeight: "bold" }}>
                                {parseInt(pendingDays * -1)} Day(s) Overdue!
                              </p>
                            );
                          } else if (parseInt(pendingDays) === 0) {
                            return (
                              <p style={{ color: "blue", fontWeight: "bold" }}>
                                Due Today!
                              </p>
                            );
                          } else if (4 > parseInt(pendingDays) > 0) {
                            return (
                              <p style={{ color: "gray", fontWeight: "bold" }}>
                                Coming Up Soon!
                              </p>
                            );
                          } else {
                            return (
                              <p style={{ color: "gray" }}>
                                {parseInt(pendingDays)} Day(s) Remaining!
                              </p>
                            );
                          }
                        };

                        const getStatus = () => {
                          if (sale.workOrder.status === "Pending Parts") {
                            return (
                              <i
                                className="fas fa-ellipsis-h"
                                style={{
                                  padding: "0.3rem",
                                  border: "1px solid #FFFD78",
                                  borderRadius: "50%",
                                  color: "#ebe424",
                                  backgroundColor: "#FFFD78",
                                }}
                              ></i>
                            );
                          } else if (
                            sale.workOrder.status === "Pending Paint"
                          ) {
                            return (
                              <i
                                className="fas fa-ellipsis-h"
                                style={{
                                  padding: "0.3rem",
                                  border: "1px solid #FFFD78",
                                  borderRadius: "50%",
                                  color: "#ebe424",
                                  backgroundColor: "#FFFD78",
                                }}
                              ></i>
                            );
                          } else if (sale.workOrder.status === "Prep") {
                            return (
                              <i
                                className="fas fa-tasks"
                                style={{
                                  padding: "0.3rem",
                                  border: "1px solid #91D2FF",
                                  borderRadius: "50%",
                                  color: "rgb(28, 115, 255)",
                                  backgroundColor: "#91D2FF",
                                }}
                              ></i>
                            );
                          } else if (sale.workOrder.status === "Making Door") {
                            return (
                              <i
                                className="fas fa-tasks"
                                style={{
                                  padding: "0.3rem",
                                  border: "1px solid #91D2FF",
                                  borderRadius: "50%",
                                  color: "rgb(28, 115, 255)",
                                  backgroundColor: "#91D2FF",
                                }}
                              ></i>
                            );
                          } else if (
                            sale.workOrder.status === "Ready For Installation"
                          ) {
                            return (
                              <i
                                className="fas fa-thumbs-up"
                                style={{
                                  padding: "0.3rem",
                                  border: "1px solid #C1FF9D",
                                  borderRadius: "50%",
                                  color: "rgb(0, 207, 0)",
                                }}
                              ></i>
                            );
                          } else if (
                            sale.workOrder.status === "Ready For Pick Up"
                          ) {
                            return (
                              <i
                                className="fas fa-thumbs-up"
                                style={{
                                  padding: "0.3rem",
                                  border: "1px solid #C1FF9D",
                                  borderRadius: "50%",
                                  color: "rgb(0, 207, 0)",
                                }}
                              ></i>
                            );
                          }
                        };

                        if (index < 5) {
                          return (
                            <tr key={index}>
                              <td>
                                <div className="table-description-section">
                                  <div className="table-description-section-index">
                                    {index + 1}
                                  </div>

                                  <div className="table-description-section-text">
                                    <h6>
                                      {customer[0].firstName}{" "}
                                      {customer[0].lastName}
                                    </h6>
                                    <p>
                                      Date Ordered:{" "}
                                      {Moment(sale.orderDate).format("LL")}
                                    </p>
                                    <p>{sale.product}</p>
                                    <p>Location: {customer[0].city}</p>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="table-description-section-text">
                                  <p style={{ color: "black" }}>
                                    {Moment(sale.expectedCompletionDate).format(
                                      "LL"
                                    )}
                                  </p>
                                  {!sale.workOrder.status.includes("Ready") &&
                                    getPendingDays()}
                                </div>
                              </td>
                              <td>
                                {sale.workOrder.status} {getStatus()}
                              </td>
                            </tr>
                          );
                        }
                      })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="loading">
          <Spinner
            animation="border"
            role="status"
            style={{ color: " rgb(84, 163, 202)" }}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
