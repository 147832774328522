import React, { useContext, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { CustomersContext } from "../../context/customersContext";
import "./FormCustomer.css";
import "./Form.css";

const FormCustomer = (props) => {
  const { action } = useContext(CustomersContext);
  const [newCustomer, setNewCustomer] = useState({
    contractor: "Yes",
    company: "",
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    address: "",
    city: "",
    province: "ON",
    postalCode: "",
    notes: [],
    recentActivities: [],
  });

  const [validationErrors, setValidationErrors] = useState([]);

  const [customerAdded, setCustomerAdded] = useState(false);

  const handleUpdate = (event) => {
    const { name, value } = event.target;

    setNewCustomer({
      ...newCustomer,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Form Validation
    if (newCustomer.firstName === "" || newCustomer.mobile === "") {
      let validations = [];
      Object.entries(newCustomer).map((customer) => {
        if (
          (customer[0] === "firstName" || customer[0] === "mobile") &&
          customer[1] === ""
        ) {
          validations.push(customer[0]);
        }
      });

      setValidationErrors(validations);
    } else {
      // Add Customer and reset fields
      try {
        await action.addCustomer(newCustomer);
        setCustomerAdded(true);

        setNewCustomer({
          contractor: "Yes",
          company: "",
          firstName: "",
          lastName: "",
          mobile: "",
          email: "",
          address: "",
          city: "",
          province: "",
          postalCode: "",
          notes: {
            date: "",
            description: "",
          },
        });
      } catch (error) {
        console.log("Error: ", error);
      }
    }
  };

  const inputField = (labelName, field, value) => {
    return (
      <div className="Form-section-1-input">
        <div className="Form-input-container">
          <span className="Form-label">{labelName}</span>
          <input
            className="Form-input"
            type="text"
            name={field}
            value={value}
            onChange={handleUpdate}
            placeholder={labelName}
          />
          <span className="Form-input-focus"></span>
        </div>
        <span className="Form-error-message">
          {validationErrors.length > 0 &&
            validationErrors.includes(field) &&
            "Required"}
        </span>
      </div>
    );
  };

  return (
    <div className="FormCustomer">
      {/* Display Successf Message If Added */}
      {customerAdded && (
        <button onClick={() => setCustomerAdded(false)}>
          <div className="FormCustomer-successful-message">
            <i class="fas fa-thumbs-up"></i>
            <p>Customer Successfully Added!</p>
            <div className="FormCustomer-success-message-btn">
              <button
                className="btn btn-success"
                onClick={() => setCustomerAdded(false)}
              >
                Add Another Customer
              </button>
              <button
                className="btn btn-danger"
                onClick={() => props.setShowModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </button>
      )}

      {/* DISPLAY VALIDATION ERRORS */}
      {validationErrors.length > 0 && (
        <div>
          <p style={{ color: "red", fontWeight: "bold" }}>
            *Please fill out the required fields
          </p>
        </div>
      )}

      <h5 className="title">Customer</h5>
      <form className="FormCustomer-form" onSubmit={handleSubmit}>
        <div className="Form-section-1">
          <div className="Form-section-1-input" style={{ width: "40%" }}>
            <div className="Form-input-container">
              <span className="Form-label">Contractor</span>
              <select
                className="Form-input"
                name="contractor"
                value={newCustomer.contractor}
                onChange={handleUpdate}
                placeholder="Contractor"
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
              <span className="Form-input-focus"></span>
            </div>
            <span className="Form-error-message">{/* error message */}</span>
          </div>

          {inputField("Company", "company", newCustomer.company)}
        </div>

        <div className="Form-section-1">
          {inputField("First Name", "firstName", newCustomer.firstName)}

          {inputField("Last Name", "lastName", newCustomer.lastName)}
        </div>

        <div className="Form-section-1">
          <div className="Form-section-1-input">
            <div className="Form-input-container">
              <span className="Form-label">Mobile</span>

              <PhoneInput
                inputStyle={{ margin: "0.45rem", border: "none" }}
                country="ca"
                disableDropdown={true}
                disableCountryGuess={true}
                disableCountryCode={true}
                value={newCustomer.mobile}
                onChange={(value) =>
                  setNewCustomer({ ...newCustomer, mobile: value })
                }
                placeholder="Enter a phone number"
              />
              <span className="Form-input-focus"></span>
            </div>
            <span className="Form-error-message">
              {" "}
              {validationErrors.length > 0 &&
                validationErrors.includes("mobile") &&
                "Required"}
            </span>
          </div>

          {inputField("Email", "email", newCustomer.email)}
        </div>

        <div className="Form-section-1">
          {inputField("Address", "address", newCustomer.address)}
        </div>

        <div className="Form-section-1">
          {inputField("City", "city", newCustomer.city)}

          <div className="Form-section-1-input">
            <div className="Form-input-container">
              <span className="Form-label">Province</span>
              <select
                className="Form-input"
                name="province"
                onChange={handleUpdate}
              >
                <option value="ON">ON</option>
                <option value="AB">AB</option>
                <option value="BC">BC</option>
                <option value="MB">MB</option>
                <option value="NB">NB</option>
                <option value="NL">NL</option>
                <option value="NT">NT</option>
                <option value="NS">NS</option>
                <option value="NU">NU</option>
                <option value="PE">PE</option>
                <option value="QC">QC</option>
                <option value="SK">SK</option>
                <option value="YT">YT</option>
              </select>
              <span className="Form-input-focus"></span>
            </div>
            <span className="Form-error-message">{/* error message */}</span>
          </div>

          {inputField("Postal Code", "postalCode", newCustomer.postalCode)}
        </div>

        <div className="Form-add-button">
          <button className="btn btn-success">Add</button>
        </div>
      </form>
    </div>
  );
};

export default FormCustomer;
