import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import "../forms/Form.css";

function DoorNoteModal(props) {
  const { addNote, showNotesModal, setShowNotesModal } = props;
  const [note, setNote] = useState({
    description: "",
  });

  const handleUpdate = (event) => {
    event.preventDefault();

    const { name, value } = event.target;

    setNote({
      ...note,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    addNote(note);

    setNote("");

    setShowNotesModal(false);
  };

  return (
    <Dialog
      open={showNotesModal}
      onClose={() => setShowNotesModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Door Note"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <form>
            <div className="Form-section-1-input">
              <div className="Form-input-container">
                <span className="Form-label">Note</span>
                <textarea
                  className="Form-input"
                  type="text"
                  name="description"
                  value={note.description}
                  onChange={handleUpdate}
                />
                <span className="Form-input-focus"></span>
              </div>
            </div>
          </form>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowNotesModal(false)} color="primary">
          Cancel
        </Button>
        <Button className="btn btn-success" onClick={handleSubmit} autoFocus>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DoorNoteModal;
