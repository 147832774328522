import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { SalesContext } from "../../context/salesContext";

// Component and other props are passed as parameter
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { salesList } = useContext(SalesContext);

  return (
    <div>
      <Route
        {...rest} // props (path) are passed with ...rest
        // render component if authenticated; else redirect to root url
        render={(props) =>
          salesList ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: rest.location.pathname }, // set from path to current location user tried to access
              }}
            />
          )
        }
      />
    </div>
  );
};

export default PrivateRoute;
