import React, { createContext, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie"; // import JavaScript Cookie library
import url from "../data/url.json";

export const LoginContext = createContext();

export const Provider = (props) => {
  // set authenticatedUser.user to cookie or null
  const [authenticatedUser, setAuthenticatedUser] = useState(
    Cookies.getJSON("encodedCredentials") || null
  );

  const [currentUser, setCurrentUser] = useState(
    Cookies.getJSON("authenticatedUser") || null
  );

  const signIn = async (user) => {
    try {
      // encode emailaddress and passsword passed from parameter
      const encodedCredentials = btoa(`${user.username}:${user.password}`);

      // create options object to pass as header in GET method
      // passes encoded credentials as basic authorization
      const options = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Authorization: `Basic ${encodedCredentials}`,
        },
      };

      // make API fetch to users GET route and pass options as 2nd parameter
      // if successful, return user; else return null;

      const response = await axios.get(`${url}/api/users`, options);

      const retrievedUser = response.data;

      // set authenticatedUser and user state
      setAuthenticatedUser(encodedCredentials);
      setCurrentUser(retrievedUser);

      // Set Cookie to store signed in user and encodedCredentials
      // first arguement is the name of the cookie to set
      // second arguement is the value to store (store stringified user object)
      // third arguement sets when the cookie expires
      Cookies.set(
        "authenticatedUser",
        JSON.stringify(retrievedUser)
        // ,{
        //   expires: 1,
        // }
      );
      Cookies.set(
        "encodedCredentials",
        JSON.stringify(encodedCredentials)
        // ,{
        //   expires: 1,
        // }
      );

      return retrievedUser;
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const signOut = () => {
    setAuthenticatedUser(null);
    setCurrentUser(null);
    // delete coookie when signing out
    Cookies.remove("authenticatedUser");
    Cookies.remove("encodedCredentials");
  };

  return (
    <LoginContext.Provider
      value={{
        authenticatedUser,
        currentUser,
        action: {
          signIn,
          signOut,
        },
      }}
    >
      {props.children}
    </LoginContext.Provider>
  );
};
