import React, { useContext, useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";

import Customer from "../details/Customer";
import { CustomersContext } from "../../context/customersContext";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "rgb(146, 200, 235)",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const CustomerModal = (props) => {
  const classes = useStyles();

  const {
    open,
    setOpen,
    selectedCustomer,
    handleClose,
    handleClickOpen,
  } = props;
  const { action } = useContext(CustomersContext);
  const [deleteModal, setDeleteModal] = useState(false);

  const closeDeleteModal = () => setDeleteModal(false);
  const showDeleteModal = () => setDeleteModal(true);

  const handleDelete = async () => {
    try {
      if (selectedCustomer) {
        action.deleteCustomer(selectedCustomer._id);
        setDeleteModal(false);
        setOpen(false);
        window.location.reload();
      }
    } catch (error) {
      console.log("Error Deleting Customer: ", error);
    }
  };

  return (
    <div>
      {selectedCustomer && (
        <div>
          {/* Customer Detail Modal */}
          <Dialog fullScreen open={open} onClose={handleClose}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  CUSTOMER INFORMATION
                </Typography>
                <Button autoFocus color="inherit" onClick={showDeleteModal}>
                  Delete
                </Button>
              </Toolbar>
            </AppBar>
            <List>
              <Customer
                customer={selectedCustomer}
                handleClickOpen={handleClickOpen}
              />
            </List>
          </Dialog>

          {/* Delete Warning Modal */}
          <div>
            <Dialog
              open={deleteModal}
              onClose={closeDeleteModal}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete this customer?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Deleting this customer will delete all sales associated with
                  this customer!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDeleteModal} color="primary">
                  Cancel
                </Button>
                <Button
                  className="btn btn-danger"
                  onClick={handleDelete}
                  color="secondary"
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerModal;
