import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import PrivateRoute from "../router/PrivateRoute";
import LoggedInRedirect from "../router/LoggedInRedirect";
import RedirectRoute from "../router/RedirectRoute";
import Login from "../forms/Login";
import Navbar from "../main/Navbar";
import Dashboard from "../main/Dashboard";
import Products from "../main/Products";
import Customers from "../main/Customers";
import Sales from "../main/Sales";
import WorkOrders from "../main/WorkOrders";
import WorkOrder from "../details/WorkOrder";
import Admin from "../main/Admin";
import SummaryReports from "../main/SummaryReports";

const Routers = () => {
  return (
    <div>
      <LoggedInRedirect
        exact
        path="/login"
        component={(routeProps) => <Login {...routeProps} />}
      />
      <Navbar>
        <Switch>
          <PrivateRoute exact path="/" component={Dashboard} />
          {/* <Route exact path="/" render={() => <Dashboard />} /> */}
          <PrivateRoute exact path="/products" component={Products} />
          <PrivateRoute exact path="/customers" component={Customers} />
          <PrivateRoute exact path="/sales" component={Sales} />
          <PrivateRoute exact path="/work-orders" component={WorkOrders} />
          <PrivateRoute exact path="/admin" component={Admin} />
          <RedirectRoute
            exact
            path="/work-orders/:id"
            component={(routeProps) => <WorkOrder {...routeProps} />}
          />
          <PrivateRoute
            exact
            path="/summary-reports"
            component={SummaryReports}
          />
        </Switch>
      </Navbar>
    </div>
  );
};

export default Routers;
