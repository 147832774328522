import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";

import { LoginContext } from "../../context/loginContext";
import Form from "../forms/Form";
import SaleFormModal from "../modals/SaleFormModal";
import "./Navbar.css";

const Navbar = (props) => {
  // FORM MODAL
  const [showModal, setShowModal] = useState(false);

  // SALE FORM MODAL
  const [open, setOpen] = useState(false);

  const { action, currentUser } = useContext(LoginContext);

  return (
    <div>
      <div className="Sidebar">
        <a
          className="nav-link nav-brand"
          id="v-pills-home-tab"
          data-toggle="pill"
          href="/"
          role="tab"
          aria-controls="v-pills-home"
          aria-selected="true"
        >
          S D
        </a>
        <div className="Sidebar-menu">
          <div
            className="nav flex-column nav-pills Sidebar-menu-links"
            id="v-pills-tab"
            role="tablist"
            aria-orientation="vertical"
          >
            <NavLink
              exact
              to="/"
              className="nav-link"
              activeClassName="nav-link active"
              title="Dashboard"
            >
              <i className="fas fa-home"></i>
            </NavLink>
            {/* <NavLink
              to="/products"
              className="nav-link"
              activeClassName="nav-link active"
              title="Products"
            >
              <i className="fas fa-box-open"></i>
            </NavLink> */}
            <NavLink
              to="/customers"
              className="nav-link"
              activeClassName="nav-link active"
              title="Customers"
            >
              <i className="fas fa-user"></i>
            </NavLink>
            <NavLink
              to="/sales"
              className="nav-link"
              activeClassName="nav-link active"
              title="Sales"
            >
              <i className="fas fa-shopping-cart"></i>
            </NavLink>
            <NavLink
              to="/work-orders"
              className="nav-link"
              activeClassName="nav-link active"
              title="Work Orders"
            >
              <i className="far fa-calendar-check"></i>
            </NavLink>
            <NavLink
              to="/admin"
              className="nav-link"
              activeClassName="nav-link active"
              title="Admin"
            >
              <i className="fas fa-sliders-h"></i>
            </NavLink>
            {/* <NavLink
              to="/summary-reports"
              className="nav-link"
              activeClassName="nav-link active"
              title="Summary Reports"
            >
              <i className="fas fa-chart-pie"></i>
            </NavLink> */}
          </div>
          <button
            onClick={() => {
              setShowModal(true);
            }}
            className="nav-link"
            style={{
              margin: 0,
              paddingTop: "5rem",
              fontSize: "3rem",
              color: "rgb(146, 200, 235)",
            }}
            title="Add"
          >
            <i className="fas fa-plus-circle"></i>
          </button>
        </div>
      </div>

      <Form
        show={showModal}
        onHide={() => setShowModal(false)}
        showModal={showModal}
        setShowModal={setShowModal}
        setOpen={setOpen}
      />

      <SaleFormModal
        open={open}
        setOpen={setOpen}
        setShowModal={setShowModal}
        handleClose={() => setOpen(false)}
        handleClickOpen={() => setOpen(true)}
      />

      <div className="content">{props.children}</div>
    </div>
  );
};

export default Navbar;
