import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { LoginContext } from "../../context/loginContext";

// Component and other props are passed as parameter
const PrivateRoute = ({ component: Component, ...rest }) => {
  const { authenticatedUser } = useContext(LoginContext);

  return (
    <div>
      <Route
        {...rest} // props (path) are passed with ...rest
        // render component if authenticated; else redirect to root url
        render={(props) =>
          authenticatedUser ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: rest.location.pathname }, // set from path to current location user tried to access
              }}
            />
          )
        }
      />
    </div>
  );
};

export default PrivateRoute;
