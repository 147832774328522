import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Moment from "moment";

import { SalesContext } from "../../context/salesContext";
import { CustomersContext } from "../../context/customersContext";
import "./WorkOrders.css";

const WorkOrders = () => {
  const {
    loading,
    filter,
    salesList,
    salesPagination,
    search,
    scrollPosition,
    workOrders,
    action,
  } = useContext(SalesContext);
  const { customers } = useContext(CustomersContext);

  // ON RENDER
  useEffect(() => {
    if (scrollPosition > 0) {
      window.scrollTo(0, scrollPosition);
    }
  });

  useEffect(() => {
    if (search === "") {
      getWorkOrders(filter);
    }
  }, [salesList]);

  // SCROLL POSITION
  const handleScrollPosition = () => {
    const position = window.pageYOffset;
    action.setScrollPosition(position);
  };

  useEffect(() => {
    if (salesList && filter === "All" && search === "") {
      action.setWorkOrders(
        salesList.filter((sale) => sale.workOrder.status !== "Completed")
      );
    }
  }, [salesList]);

  const getWorkOrders = (tag) => {
    if (salesList) {
      if (tag === "All") {
        action.setWorkOrders(
          salesList.filter((sale) => sale.workOrder.status !== "Completed")
        );
      } else if (tag === "Parts") {
        action.setWorkOrders(
          salesList.filter((sale) => sale.workOrder.status === "Pending Parts")
        );
      } else if (tag === "Prep") {
        action.setWorkOrders(
          salesList.filter((sale) => sale.workOrder.status === "Prep")
        );
      } else if (tag === "Ship") {
        action.setWorkOrders(
          salesList.filter((sale) => sale.workOrder.status === "Ship")
        );
      } else if (tag === "Paint") {
        action.setWorkOrders(
          salesList.filter((sale) => sale.workOrder.status === "Pending Paint")
        );
      } else if (tag === "Door") {
        action.setWorkOrders(
          salesList.filter((sale) => sale.workOrder.status === "Making Door")
        );
      } else if (tag === "Ready") {
        action.setWorkOrders(
          salesList.filter(
            (sale) =>
              sale.workOrder.status === "Ready For Installation" ||
              sale.workOrder.status === "Ready For Pick Up"
          )
        );
      } else if (tag === "Processed") {
        action.setWorkOrders(
          salesList.filter((sale) => sale.workOrder.status === "Processed")
        );
      } else if (tag === "Completed") {
        action.setWorkOrders(
          salesList.filter((sale) => sale.workOrder.status === "Completed")
        );
      }
    } else {
      return [];
    }
  };

  const filterButton = (event) => {
    event.preventDefault();
    action.setScrollPosition(0);

    action.setSearch("");
    action.setFilter(
      event.target.innerHTML.substr(0, event.target.innerHTML.indexOf(" "))
    );
    getWorkOrders(
      event.target.innerHTML.substr(0, event.target.innerHTML.indexOf(" "))
    );
  };

  const handleSearch = (event) => {
    event.preventDefault();

    if (event.target.value === "") {
      action.setSearch("");
      getWorkOrders("All");
    } else {
      action.setSearch(event.target.value);
      // FIND CUSTOMER WITH SEARCH
      const searchedCustomers = customers.filter((customer) => {
        customer.fullName = `${customer.firstName} ${customer.lastName}`;

        return Object.entries(customer).some(
          (value) =>
            typeof value[1] === "string" &&
            (value[0] === "fullName" || value[0] === "mobile") &&
            value[1].toLowerCase().includes(event.target.value.toLowerCase())
        );
      });

      // array of customer Ids
      const customerIds = searchedCustomers.map((customers) => customers._id);

      // filter workOrder list with customers Id
      let searchInputCustomer = salesList
        .filter((workOrder) => customerIds.includes(workOrder.customerId))
        .filter((workOrder) => workOrder.workOrder.status !== "Completed");

      // let searchInputCustomerCompleted = salesList
      //   .filter((workOrder) => customerIds.includes(workOrder.customerId))
      //   .filter((workOrder) => workOrder.workOrder.status === "Completed");

      // searchInputCustomerCompleted.map((sale) => {
      //   searchInputCustomer.push(sale);
      // });

      // Display Search results
      if (searchInputCustomer.length > 0 && search !== "") {
        action.setWorkOrders(searchInputCustomer);
      } else {
        // FIND PO WITH SEARCH
        const searchedPo = salesList.filter((workOrder) => {
          return Object.entries(workOrder).some(
            (value) =>
              typeof value[1] === "string" &&
              value[0] === "po" &&
              value[1].toLowerCase().includes(event.target.value.toLowerCase())
          );
        });

        // array of customer Ids
        const pos = searchedPo.map((pos) => pos.po);

        // filter workOrder list with customers Id
        let searchInputPo = salesList
          .filter((workOrder) => pos.includes(workOrder.po))
          .filter((workOrder) => workOrder.workOrder.status !== "Completed");

        action.setWorkOrders(searchInputPo);
      }
    }
  };

  // SET WORKORDERS ARRAY TO ALL BEFORE SEARCHING
  const resetField = (event) => {
    event.preventDefault();
    if (event.target.value === "") {
      if (filter !== "Completed" && filter !== "All") {
        action.setFilter("All");
        getWorkOrders("All");
      }
    }
  };

  return (
    <div className="WorkOrders">
      {salesList ? (
        <div className="WorkOrders-container">
          <div className="WorkOrders-searchbar-container">
            <input
              type="search"
              placeholder="Search PO #, customer, or phone number"
              value={search}
              onChange={handleSearch}
              onFocus={resetField}
            />
          </div>
          <div className="WorkOrders-filters-container">
            <button
              className={`WorkOrders-filters-container-filter ${
                filter === "All" && "active-all"
              }`}
              onClick={filterButton}
            >
              All {filter === "All" && `(${workOrders.length})`}
            </button>
            <button
              className={`WorkOrders-filters-container-filter ${
                filter === "Processed" && "active-processed"
              } `}
              onClick={filterButton}
            >
              Processed {filter === "Processed" && `(${workOrders.length})`}
            </button>
            <button
              className={`WorkOrders-filters-container-filter ${
                filter === "Parts" && "active-pending-parts"
              } `}
              onClick={filterButton}
            >
              Parts {filter === "Parts" && `(${workOrders.length})`}
            </button>

            <button
              className={`WorkOrders-filters-container-filter ${
                filter === "Prep" && "active-prep"
              } `}
              onClick={filterButton}
            >
              Prep {filter === "Prep" && `(${workOrders.length})`}
            </button>

            <button
              className={`WorkOrders-filters-container-filter ${
                filter === "Ship" && "active-ship"
              } `}
              onClick={filterButton}
            >
              Ship {filter === "Ship" && `(${workOrders.length})`}
            </button>

            <button
              className={`WorkOrders-filters-container-filter ${
                filter === "Paint" && "active-pending-paint"
              } `}
              onClick={filterButton}
            >
              Paint {filter === "Paint" && `(${workOrders.length})`}
            </button>
            <button
              className={`WorkOrders-filters-container-filter ${
                filter === "Door" && "active-door"
              } `}
              onClick={filterButton}
            >
              Door {filter === "Door" && `(${workOrders.length})`}
            </button>
            <button
              className={`WorkOrders-filters-container-filter ${
                filter === "Ready" && "active-ready"
              } `}
              onClick={filterButton}
            >
              Ready {filter === "Ready" && `(${workOrders.length})`}
            </button>
            <button
              className={`WorkOrders-filters-container-filter ${
                filter === "Completed" && "active-completed"
              } `}
              onClick={filterButton}
            >
              Completed {filter === "Completed" && `(${workOrders.length})`}
            </button>
          </div>

          <div className="WorkOrders-card-container">
            {workOrders.length > 0 ? (
              <div>
                {/* SORT LIST BASED ON EXPECTED COMPLETION DATES */}
                {workOrders
                  .sort((a, b) => {
                    let x = a.expectedCompletionDate;
                    let y = b.expectedCompletionDate;
                    if (x < y) {
                      return -1;
                    }
                    if (x > y) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((sale, index) => {
                    // find customer with customerId
                    const customer = customers
                      ? customers.find((customer) => {
                          return customer._id === sale.customerId;
                        })
                      : {};

                    // Calc pending days
                    const getPendingDays = () => {
                      let expectedCompletionDate = Moment(
                        sale.expectedCompletionDate
                      ).startOf("day");

                      let todaysDate = Moment().startOf("day");

                      let pendingDays = expectedCompletionDate.diff(
                        todaysDate,
                        "days"
                      );

                      if (parseInt(pendingDays) < 0) {
                        return (
                          <span style={{ color: "red", fontWeight: "bold" }}>
                            {parseInt(pendingDays * -1)} Day(s) Overdue!
                          </span>
                        );
                      } else if (parseInt(pendingDays) === 0) {
                        return (
                          <span style={{ color: "blue", fontWeight: "bold" }}>
                            Due Today!
                          </span>
                        );
                      } else if (4 > parseInt(pendingDays) > 0) {
                        return (
                          <span style={{ color: "gray", fontWeight: "bold" }}>
                            Coming Up Soon!
                          </span>
                        );
                      } else {
                        return (
                          <span style={{ color: "gray" }}>
                            {parseInt(pendingDays)} Day(s) Remaining!
                          </span>
                        );
                      }
                    };

                    const activeColor = () => {
                      if (sale.workOrder.status === "Processed") {
                        return "#6b6b6b";
                      } else if (sale.workOrder.status === "Pending Parts") {
                        return "#ebe424";
                      } else if (sale.workOrder.status === "Prep") {
                        return "rgb(22, 75, 158)";
                      } else if (sale.workOrder.status === "Ship") {
                        return "rgb(26, 153, 26)";
                      } else if (sale.workOrder.status === "Pending Paint") {
                        return "orange";
                      } else if (sale.workOrder.status === "Making Door") {
                        return "rgb(28, 115, 255)";
                      } else if (
                        sale.workOrder.status === "Ready For Installation"
                      ) {
                        return "rgb(0, 207, 0)";
                      } else if (
                        sale.workOrder.status === "Ready For Pick Up"
                      ) {
                        return "rgb(0, 207, 0)";
                      } else if (sale.workOrder.status === "Completed") {
                        return "rgb(222,222,222)";
                      }
                    };

                    const activeHighlight = {
                      borderLeft: "5px solid",
                      borderRadius: "5px",
                      borderColor: activeColor(),
                    };

                    const activeBackground = {
                      backgroundColor: activeColor(),
                      color: "white",
                    };

                    return (
                      <Link
                        to={{
                          pathname: `work-orders/${sale._id}`,
                        }}
                        className="WorkOrders-card-container-card"
                        style={activeHighlight}
                        key={index}
                        onClick={handleScrollPosition}
                      >
                        <div>
                          <h6>
                            {index + 1}: {customer.firstName}{" "}
                            {customer.lastName}
                          </h6>
                          <p>
                            <span className="bold">PO #:</span> {sale.po}
                          </p>
                          <hr />
                          <p>
                            <span className="bold">Door System:</span>{" "}
                            {sale.productSpecification.doorSystem}
                            {sale.productSpecification.sidelightLeft
                              .sidelight === "Yes" && ", Left Sidelight"}
                            {sale.productSpecification.sidelightRight
                              .sidelight === "Yes" && ", Right Sidelight"}
                            {sale.productSpecification.transom.transomBox ===
                              "Yes" && ", Transom"}
                          </p>
                          <p>
                            <span
                              style={{
                                fontWeight:
                                  !sale.productSpecification.doorLeft.paintOut.includes(
                                    "Factory"
                                  ) && "bold",
                                color:
                                  !sale.productSpecification.doorLeft.paintOut.includes(
                                    "Factory"
                                  ) && "#AA0000",
                              }}
                            >
                              <span className="bold">Paint Out:</span>{" "}
                              {sale.productSpecification.doorLeft.paintOut}
                            </span>
                          </p>
                          <span
                            style={{
                              fontWeight:
                                !sale.productSpecification.doorLeft.paintIn.includes(
                                  "Factory"
                                ) && "bold",
                              color:
                                !sale.productSpecification.doorLeft.paintIn.includes(
                                  "Factory"
                                ) && "#AA0000",
                            }}
                          >
                            <span className="bold">Paint In:</span>{" "}
                            {sale.productSpecification.doorLeft.paintIn}
                          </span>

                          <p></p>

                          <p>
                            <span className="bold">Expected:</span>{" "}
                            {Moment(sale.expectedCompletionDate).format("LL")}
                          </p>
                          <p>
                            {!sale.workOrder.status.includes("Ready") &&
                              !sale.workOrder.status.includes("Completed") &&
                              getPendingDays()}
                          </p>
                        </div>
                        <div
                          className="WorkOrders-card-container-card-status"
                          style={activeBackground}
                        >
                          <p>{sale.workOrder.status}</p>
                        </div>
                      </Link>
                    );
                  })}
              </div>
            ) : (
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "5rem",
                  fontSize: "3rem",
                  fontWeight: "bold",
                }}
              >
                {filter === "All" && "No Work!"}
                {filter === "Ready" && "Nothing Ready!"}
                {filter === "Door" && "No Doors To Make!"}
                {filter === "Pending" && "Not Waiting On Parts!"}
                {filter === "Processed" && "Nothing New Processed!"}
                {filter === "Completed" && "Nothing Completed"}
              </p>
            )}
          </div>
        </div>
      ) : (
        <div className="loading">
          <Spinner
            animation="border"
            role="status"
            style={{ color: " rgb(84, 163, 202)" }}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
};

export default WorkOrders;
