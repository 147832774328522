import React, { useContext, useState } from "react";

import Collapsible from "react-collapsible";
import { LoginContext } from "../../context/loginContext";
import DoorStyles from "../../data/doorStyles.json";
import "./Admin.css";

const Admin = () => {
  const { action, currentUser } = useContext(LoginContext);
  // Add option to add door styles
  /*   
  const [newStyle, setNewStyle] = useState({
    brand: "",
    style: "",
    description: "",
  });
  const [doorStyleInput, setDoorStyleInput] = useState(false);

  const updateDoorStyle = (event) => {
    event.preventDefault();

    const { name, value } = event.target;

    setNewStyle({
      ...newStyle,
      [name]: value,
    });
  };

  const addDoorStyle = (event) => {
    event.preventDefault();

    setDoorStyleInput(true);
  };

  const cancelDoorStyle = (event) => {
    event.preventDefault();

    setNewStyle({
      brand: "",
      style: "",
      description: "",
    });

    setDoorStyleInput(false);
  };

  const submitDoorStyle = (event) => {
    event.preventDefault();
    console.log(typeof DoorStyles);
    DoorStyles.push(newStyle);
  }; */

  return (
    <div className="Admin">
      <div className="Admin-container">
        {currentUser && (
          <div className="Admin-header">
            <h4 className="title">Current User: {currentUser.username}</h4>
            <span>
              <button
                className="btn logout-btn"
                onClick={() => action.signOut()}
              >
                Logout
              </button>
            </span>
          </div>
        )}
        <div
          style={{
            marginTop: "5rem",
            width: "100%",
            height: "2px",
            marginBottom: "1rem",
            border: "1px solid rgb(84, 163, 202)",
            backgroundColor: "rgb(84, 163, 202)",
          }}
        ></div>

        <Collapsible
          className="collapsible-section"
          trigger="Users"
          open={true}
        >
          <table className="Admin-table">
            <tr>
              <th></th>
              <th>Username</th>
              <th>Password</th>
            </tr>
          </table>
          <button className="btn Admin-btn">Add</button>

          {/* Add option to add doorstyles later */}
          {/* {doorStyleInput ? (
            <div className="Admin-button-container">
              <button
                className="btn btn-outline-danger"
                onClick={cancelDoorStyle}
              >
                Cancel
              </button>
              <button className="btn btn-success" onClick={submitDoorStyle}>
                Submit
              </button>
            </div>
          ) : (
            <button className="btn Admin-btn" onClick={addDoorStyle}>
              Add
            </button>
          )} */}
        </Collapsible>

        <Collapsible
          className="collapsible-section"
          trigger="Door Styles"
          open={false}
        >
          <table className="Admin-table">
            <tr>
              <th></th>
              <th>Brand</th>
              <th>Style</th>
              <th>Description</th>
            </tr>
            {DoorStyles.map((doorStyle, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    {" "}
                    <tr className="Admin-input-field">{doorStyle.brand}</tr>
                  </td>
                  <td>
                    {" "}
                    <tr className="Admin-input-field">{doorStyle.style}</tr>
                  </td>
                  <td>
                    {" "}
                    <tr className="Admin-input-field">
                      {doorStyle.description}
                    </tr>
                  </td>
                  {/* <td>
                    <i className="Admin-delete fas fa-minus-circle"></i>
                  </td> */}
                </tr>
              );
            })}
          </table>

          {/* Add option to add doorstyles later */}
          {/* {doorStyleInput ? (
            <div className="Admin-button-container">
              <button
                className="btn btn-outline-danger"
                onClick={cancelDoorStyle}
              >
                Cancel
              </button>
              <button className="btn btn-success" onClick={submitDoorStyle}>
                Submit
              </button>
            </div>
          ) : (
            <button className="btn Admin-btn" onClick={addDoorStyle}>
              Add
            </button>
          )} */}
        </Collapsible>
      </div>
    </div>
  );
};

export default Admin;
