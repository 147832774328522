import React from "react";

const SummaryReports = () => {
  return (
    <div>
      <p>SUmmary Reports</p>
    </div>
  );
};

export default SummaryReports;
