import React, { useContext, useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";

import FormSale from "../forms/FormSale";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "rgb(146, 200, 235)",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const SaleFormModal = (props) => {
  const classes = useStyles();

  const {
    open,
    setOpen,
    selectedCustomer,
    handleClose,
    handleClickOpen,
  } = props;

  return (
    <div>
      <div>
        {/* Customer Detail Modal */}
        <Dialog fullScreen open={open} onClose={handleClose}>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <button
                  className="btn btn-light"
                  onClick={() => {
                    props.setShowModal(true);
                    props.setOpen(false);
                  }}
                >
                  Back
                </button>
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                SALE FORM
              </Typography>
              <CloseIcon />
            </Toolbar>
          </AppBar>
          <List>
            <FormSale />
          </List>
        </Dialog>
      </div>
    </div>
  );
};

export default SaleFormModal;
