import React, { useState } from "react";
import Moment from "moment";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";

import "../forms/Form.css";

function PaymentModal(props) {
  const { addPayment, paymentModalShow, setPaymentModalShow } = props;
  const [payment, setPayment] = useState({
    date: Moment().format("YYYY-MM-DD"),
    amount: "",
  });

  const handleUpdate = (event) => {
    event.preventDefault();

    const { name, value } = event.target;
    setPayment({
      ...payment,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    addPayment(payment);

    setPayment({
      date: Moment().format("YYYY-MM-DD"),
      amount: "",
    });

    setPaymentModalShow(false);
  };

  return (
    <Dialog
      open={paymentModalShow}
      onClose={() => setPaymentModalShow(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Record Payment"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <form>
            <div className="Form-section-1-input">
              <div
                className="Form-input-container"
                data-validate="Name is required"
              >
                <span className="Form-label">Date</span>
                <input
                  className="Form-input"
                  type="date"
                  name="date"
                  value={payment.date}
                  onChange={handleUpdate}
                />
                <span className="Form-input-focus"></span>
              </div>
              <span className="form-error-message">{/* error message */}</span>
            </div>

            <div className="Form-section-1-input">
              <div
                className="Form-input-container"
                data-validate="Name is required"
              >
                <span className="Form-label">Amount</span>
                <input
                  className="Form-input"
                  type="number"
                  name="amount"
                  value={payment.amount}
                  onChange={handleUpdate}
                />
                <span className="Form-input-focus"></span>
              </div>
              <span className="form-error-message">{/* error message */}</span>
            </div>
          </form>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setPaymentModalShow(false)} color="primary">
          Cancel
        </Button>
        <Button className="btn btn-success" onClick={handleSubmit} autoFocus>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PaymentModal;
