import React, { useState } from "react";
import Moment from "moment";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import "../forms/Form.css";

function PaymentModal(props) {
  const { addNote, showNotesModal, setShowNotesModal } = props;
  const [note, setNote] = useState({
    date: Moment().format("LLL"),
    description: "",
  });

  const handleUpdate = (event) => {
    event.preventDefault();

    const { name, value } = event.target;
    setNote({
      ...note,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    addNote(note);

    setNote({
      date: Moment().format("YYYY-MM-DD"),
      description: "",
    });

    setShowNotesModal(false);
  };

  return (
    <Dialog
      open={showNotesModal}
      onClose={() => setShowNotesModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Record Note"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <form>
            <div className="Form-section-1-input">
              <div
                className="Form-input-container"
                data-validate="Name is required"
              >
                <span className="Form-label">Description</span>
                <textarea
                  className="Form-input"
                  type="text"
                  name="description"
                  value={note.description}
                  onChange={handleUpdate}
                />
                <span className="Form-input-focus"></span>
              </div>
              <span className="form-error-message">{/* error message */}</span>
            </div>
          </form>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setShowNotesModal(false)} color="primary">
          Cancel
        </Button>
        <Button className="btn btn-success" onClick={handleSubmit} autoFocus>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default PaymentModal;
