import React, { useContext, useEffect, useState } from "react";
import Moment from "moment";
import { Tab, Tabs } from "react-bootstrap";
import { CustomersContext } from "../../context/customersContext";
import { SalesContext } from "../../context/salesContext";
import NoteModal from "../modals/NoteModal";
import SaleModal from "../modals/SaleModal";
import CustomerSalesTable from "../tables/CustomerSalesTable";
import "./Customer.css";

const Customer = (props) => {
  const { customer, handleClickOpen } = props;
  const { action } = useContext(CustomersContext);
  const { salesList } = useContext(SalesContext);
  const salesAction = useContext(SalesContext).action;
  const [customerSales, setCustomerSales] = useState([]);
  const [editForm, setEditForm] = useState(true);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [openSale, setOpenSale] = useState(false);
  const [selectedSale, setSelectedSale] = useState();
  const [flattenPrevArray] = useState([]);
  const [flattenUpdatedArray] = useState([]);

  const [updatedCustomer, setUpdateCustomer] = useState({
    contractor: customer.contractor || "",
    company: customer.company || "",
    firstName: customer.firstName || "",
    lastName: customer.lastName || "",
    mobile: customer.mobile || "",
    email: customer.email || "",
    address: customer.address || "",
    city: customer.city || "",
    province: customer.province || "",
    postalCode: customer.postalCode || "",
    notes: customer.notes || "",
    recentActivities: customer.recentActivities || [],
    updatedDate: customer.updatedDate,
  });

  useEffect(() => {
    if (salesList) {
      const newList = salesList.filter(
        (sale) => sale.customerId === customer._id
      );

      setCustomerSales(newList);
    }
  }, [salesList]);

  // SET WindowWidth to Screen width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  window.addEventListener("resize", function () {
    setWindowWidth(window.innerWidth);
  });

  const handleUpdate = (event) => {
    const { name, value } = event.target;

    setUpdateCustomer({
      ...updatedCustomer,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await action.updateCustomer(customer._id, updatedCustomer);
      setEditForm(true);
      handleClickOpen(customer._id);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const prevArray = () => {
    let prevProperties = [];
    flattenPrevArray.length = 0;

    const getPrevRecords = (obj) => {
      for (let property in obj) {
        if (property !== "recentActivities") {
          if (typeof obj[property] === "object") {
            prevProperties.push(property);
            getPrevRecords(obj[property]);
          } else {
            if (prevProperties.length > 0) {
              prevProperties.push(property);
              flattenPrevArray.push({ [prevProperties]: obj[property] });
              prevProperties.pop();
            } else {
              flattenPrevArray.push({ [property]: obj[property] });
            }
          }
        }
      }
      prevProperties.pop();
    };
    getPrevRecords(customer);
  };

  const currArray = () => {
    let currProperties = [];
    flattenUpdatedArray.length = 0;

    const getCurrRecords = (obj) => {
      for (let property in obj) {
        if (property !== "recentActivities") {
          if (typeof obj[property] === "object") {
            currProperties.push(property);
            getCurrRecords(obj[property]);
          } else {
            if (currProperties.length > 0) {
              currProperties.push(property);
              flattenUpdatedArray.push({ [currProperties]: obj[property] });
              currProperties.pop();
            } else {
              flattenUpdatedArray.push({ [property]: obj[property] });
            }
          }
        }
      }
      currProperties.pop();
    };
    getCurrRecords(updatedCustomer);
  };

  const setRecentActivities = () => {
    prevArray();
    currArray();

    flattenPrevArray.map((prevArray) => {
      for (let prevProperties in prevArray) {
        flattenUpdatedArray.map((updatedArray) => {
          for (let updatedProperties in updatedArray) {
            if (
              prevProperties === updatedProperties &&
              prevArray[prevProperties] !== updatedArray[updatedProperties]
            ) {
              // Update if recent activites was not already added
              if (
                !updatedCustomer.recentActivities.includes(
                  `${Moment().format(
                    "LLL"
                  )}: ${prevProperties} was changed from '${
                    prevArray[prevProperties]
                  }' to '${updatedArray[updatedProperties]}'`
                )
              ) {
                updatedCustomer.recentActivities.push(
                  `${Moment().format(
                    "LLL"
                  )}: ${prevProperties} was changed from '${
                    prevArray[prevProperties]
                  }' to '${updatedArray[updatedProperties]}'`
                );
              }
            }
          }
        });
      }
    });
  };

  const toggleForm = (event) => {
    event.preventDefault();
    setEditForm(!editForm);

    if (event.target.innerHTML === "Cancel") {
      setUpdateCustomer({
        contractor: customer.contractor,
        company: customer.company,
        firstName: customer.firstName,
        lastName: customer.lastName,
        mobile: customer.mobile,
        email: customer.email,
        address: customer.address,
        city: customer.city,
        province: customer.province,
        postalCode: customer.postalCode,
        notes: customer.notes,
        recentActivities: customer.recentActivities,
      });
    }
  };

  const displayFormButton = () => {
    if (editForm) {
      return (
        <button className="btn btn-info edit" onClick={toggleForm}>
          <i className="fas fa-edit"></i>
        </button>
      );
    } else {
      return (
        <div className="Customer-edit">
          <button className="btn btn-danger" onClick={toggleForm}>
            Cancel
          </button>
          <button className="btn btn-info" onClick={handleSubmit}>
            Update
          </button>
        </div>
      );
    }
  };

  const addNote = (note) => {
    updatedCustomer.notes.push(note);

    action.updateCustomer(customer._id, updatedCustomer);
  };

  const removeNote = (selectedIndex) => {
    const updatedNotes = updatedCustomer.notes
      .slice(0)
      .reverse()
      .filter((note, index) => index !== selectedIndex)
      .slice(0)
      .reverse();

    updatedCustomer.notes = updatedNotes;
  };

  const removeRecentActivity = (selectedIndex) => {
    const updatedRecentActivity = updatedCustomer.recentActivities
      .slice(0)
      .reverse()
      .filter((recentActivity, index) => index !== selectedIndex)
      .slice(0)
      .reverse();

    updatedCustomer.recentActivities = updatedRecentActivity;
  };

  const openSaleModal = async (saleId) => {
    setOpenSale(true);

    const sale = await salesAction.getSale(saleId);
    setSelectedSale(sale);
  };

  const closeSaleModal = () => {
    setOpenSale(false);
    setSelectedSale();
  };

  const inputField = (labelName, inputName, value, type, disable) => {
    return (
      <div className="Sale-form-input-row">
        <label htmlFor={inputName}>{labelName}</label>
        <input
          className="Sale-form-input"
          type={type}
          id={inputName}
          name={inputName}
          value={value}
          onChange={handleUpdate}
          onBlur={setRecentActivities}
          disabled={disable}
        />
      </div>
    );
  };

  const CustomerLeftSection = () => {
    return (
      <div className="Customer-left-section">
        <div className="Customer-left-section-text-container">
          <div className="Sale-form-input-row">
            <label htmlFor="contractor">Contractor</label>
            <select
              className="Sale-form-input"
              id="contractor"
              name="contractor"
              value={updatedCustomer.contractor}
              onChange={handleUpdate}
              onBlur={setRecentActivities}
              disabled={editForm}
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
          </div>

          {inputField(
            "Company",
            "company",
            updatedCustomer.company,
            "text",
            editForm
          )}

          {inputField(
            "First Name",
            "firstName",
            updatedCustomer.firstName,
            "text",
            editForm
          )}
          {inputField(
            "Last Name",
            "lastName",
            updatedCustomer.lastName,
            "text",
            editForm
          )}
          {inputField(
            "Mobile",
            "mobile",
            updatedCustomer.mobile,
            "text",
            editForm
          )}

          {inputField(
            "Email",
            "email",
            updatedCustomer.email,
            "text",
            editForm
          )}

          {inputField(
            "Address",
            "address",
            updatedCustomer.address,
            "text",
            editForm
          )}
          {inputField("City", "city", updatedCustomer.city, "text", editForm)}
          {inputField(
            "Province",
            "province",
            updatedCustomer.province,
            "text",
            editForm
          )}
          {inputField(
            "Postal Code",
            "postalCode",
            updatedCustomer.postalCode,
            "text",
            editForm
          )}

          <div className="Sale-form-input-row">
            <label htmlFor="updatedDate">Last Updated</label>
            <input
              className="Sale-form-input"
              disabled={true}
              id="updatedDate"
              name="updatedDate"
              value={Moment(updatedCustomer.updatedDate).format("LLL")}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="Customer">
      <div className="Customer-edit-btn">{displayFormButton()}</div>
      <div className="Customer-top-section">
        {windowWidth > 1024 && CustomerLeftSection()}

        <div className="Customer-right-section">
          <Tabs
            defaultActiveKey={windowWidth < 1024 ? "customerDetails" : "notes"}
            id="uncontrolled-tab-example"
          >
            {windowWidth < 1024 && (
              <Tab eventKey="customerDetails" title="Customer Details">
                {CustomerLeftSection()}
              </Tab>
            )}
            <Tab eventKey="notes" title="Notes">
              <div className="Sale-top-right-payment-container">
                {updatedCustomer.notes &&
                  updatedCustomer.notes
                    .slice(0)
                    .reverse()
                    .map((note, index) => {
                      return (
                        <div key={index} className="note-card">
                          <p>
                            <span style={{ fontWeight: "bold" }}>
                              {Moment(note.date).format("LLL")}:
                            </span>{" "}
                            {note.description}
                          </p>
                          {!editForm && (
                            <button
                              className="close"
                              onClick={() => removeNote(index)}
                            >
                              x
                            </button>
                          )}
                        </div>
                      );
                    })}
              </div>

              <div className="Sale-top-right-payment-container-button">
                <button
                  className="btn btn-success"
                  onClick={() => setShowNotesModal(true)}
                >
                  Add Notes
                </button>
              </div>

              <NoteModal
                showNotesModal={showNotesModal}
                setShowNotesModal={setShowNotesModal}
                addNote={addNote}
              />
            </Tab>
            <Tab eventKey="recentActivities" title="Recent Activities">
              <div className="Sale-top-right-ra-section">
                {updatedCustomer.recentActivities
                  .slice(0)
                  .reverse()
                  .map((recentActivity, index) => {
                    return (
                      <div className="note-card" key={index}>
                        <p>{recentActivity}</p>
                        {!editForm && (
                          <button
                            className="close"
                            onClick={() => removeRecentActivity(index)}
                          >
                            x
                          </button>
                        )}
                      </div>
                    );
                  })}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      <div className="Customer-purchase-section">
        <CustomerSalesTable
          customerSales={customerSales}
          openSaleModal={openSaleModal}
        />

        <SaleModal
          selectedSale={selectedSale}
          setSelectedSale={setSelectedSale}
          open={openSale}
          setOpen={setOpenSale}
          handleClose={closeSaleModal}
          openSaleModal={openSaleModal}
        />
      </div>
    </div>
  );
};

export default Customer;
