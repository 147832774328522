import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import url from "../data/url.json";

export const SalesContext = createContext();

export const Provider = (props) => {
  const [loading, setLoading] = useState(true);
  const [salesList, setSalesList] = useState();
  const [salesPagination, setSalesPagination] = useState();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [filter, setFilter] = useState("All");
  const [search, setSearch] = useState("");
  const [workOrders, setWorkOrders] = useState([]);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    getSalesList();
  }, []);

  useEffect(() => {
		if (
			salesPagination &&
			salesPagination.currentPage < salesPagination.totalPages
		) {
			getSalesList(salesPagination.currentPage + 1);
		}
  }, [salesList]);

  const getSalesList = async (page = 1) => {
    try {
      console.log(`GETTING SALES: PAGE ${page}`);

      const { data:response } = await axios.get(`${url}/api/sales?page=${page}`);
      console.log(response);
      if (!response) return;

      setSalesPagination(response.pagination);

      if (salesList && salesList.length > 0) {
        setSalesList((prev) => [...prev, ...response.salesList]);
      } else {
        setSalesList(response.salesList);
      }
    } catch (error) {
      console.log("Retrieving Sales List Error: ", error);
    } finally {
      setLoading(false);
    }
  };

const addSale = async (newSale) => {
    try {
        const response = await axios.post(`${url}/api/sales`, newSale);
        if (!response) return;

        setSalesList(prevSalesList => [...prevSalesList, newSale]);
    } catch (error) {
        console.log("Adding Sales Error :", error);
        return error;
    }
};

  const getSale = async (salesId) => {
    try {
      const response = await axios.get(`${url}/api/sales/${salesId}`);
      return response.data;
    } catch (error) {
      console.log("Retreiving Sales Error: ", error);
    }
  };

  const updateSale = async (saleId, updatedSale) => {
    try {
      setUpdating(true);
      const response = await axios.put(
        `${url}/api/sales/${saleId}`,
        updatedSale
      );

      if (!response) return;

        setSalesList(prevSalesList => prevSalesList.map(sale => sale._id === saleId ? updatedSale : sale));
      setUpdating(false);

      return response.data;
    } catch (error) {
      console.log("Updating Sales Error: ", error);
    }
  };

const deleteSale = async (saleId) => {
    try {
        await axios.delete(`${url}/api/sales/${saleId}`);

        setSalesList(prevSalesList => prevSalesList.filter(sale => sale._id !== saleId));
    } catch (error) {
        console.log("Deleting Sales Error: ", error);
        return error;
    }
};

  const upload = async (files) => {
    try {
      const response = await axios.post(`${url}/api/sales/upload`, files);
    } catch (error) {
      console.log("Updating Sales Error: ", error);
    }
  };

  const getFiles = async (id) => {
    try {
      const response = await axios.get(`${url}/api/sales/files/${id}`, {
        responseType: "blob",
      });
      return response;
    } catch (error) {
      console.log("Getting Files Error: ", error);
    }
  };

  const deleteFile = async (file) => {
    try {
      const response = await axios.delete(`${url}/api/sales/files/${file}`);
    } catch (error) {
      console.log("Delting Files Error: ", error);
    }
  };

  const getFraction = (number) => {
    // Get greatest common divisor
    function getGcd(num1, num2) {
      if (num2 === 0) {
        return num1;
      } else {
        let remainder = num1 % num2;
        return getGcd(num2, remainder);
      }
    }

    let wholeNumber = Math.floor(number);
    let decimal = number - wholeNumber;
    let decimalLength = decimal.toString().length - 1;
    let divider;
    let numerator;
    let denominator;

    if (decimalLength === 1) {
      divider = 1;
    } else if (decimalLength === 2) {
      divider = 10;
    } else if (decimalLength === 3) {
      divider = 100;
    } else {
      divider = 1000;
    }

    let num = decimal * divider;
    let gcd = getGcd(num, divider);

    numerator = num / gcd;
    denominator = divider / gcd;

    if (numerator === 0) {
      return `${wholeNumber}`;
    } else {
      return `${wholeNumber}-${numerator}/${denominator}`;
    }
  };

  return (
    <SalesContext.Provider
      value={{
        loading,
        filter,
        salesList,
        salesPagination,
        search,
        scrollPosition,
        workOrders,
        updating,
        action: {
          upload,
          setLoading,
          updateSale,
          addSale,
          deleteSale,
          getFiles,
          getFraction,
          getSale,
          setSalesList,
          setScrollPosition,
          setSearch,
          setFilter,
          setWorkOrders,
          setUpdating,
          deleteFile,
        },
      }}
    >
      {props.children}
    </SalesContext.Provider>
  );
};
