import React from "react";

const Products = () => {
  return (
    <div>
      <p>Products Page</p>
    </div>
  );
};

export default Products;
