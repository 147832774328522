import React, { createContext, useEffect, useState, useContext } from "react";

import { SalesContext } from "./salesContext";

export const WorkOrdersContext = createContext();

export const Provider = (props) => {
  const { salesList } = useContext(SalesContext);
  const [workOrders, setWorkOrders] = useState([]);
  const [filter, setFilter] = useState("All");

  useEffect(() => {
    if (salesList) {
      getWorkOrders();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getWorkOrders = () => {
    let workorders;

    if (filter === "All") {
      workorders = salesList.filter(
        (sale) => sale.workOrder.status !== "Completed"
      );
    } else if (filter === "Pending") {
      workorders = salesList.filter((sale) =>
        sale.workOrder.status.includes("Pending")
      );
    } else if (filter === "Door") {
      workorders = salesList.filter(
        (sale) =>
          sale.workOrder.status === "Prep" ||
          sale.workOrder.status === "Making Door"
      );
    } else if (filter === "Ready") {
      workorders = salesList.filter(
        (sale) =>
          sale.workOrder.status === "Ready For Installation" ||
          sale.workOrder.status === "Ready For Pick Up"
      );
    } else if (filter === "Processed") {
      workorders = salesList.filter(
        (sale) => sale.workOrder.status === "Processed"
      );
    } else if (filter === "Completed") {
      workorders = salesList.filter(
        (sale) => sale.workOrder.status === "Completed"
      );
    }

    if (!workorders) {
      setWorkOrders([]);
    } else {
      setWorkOrders(workorders);
    }
  };

  return (
    <WorkOrdersContext.Provider
      value={{
        workOrders,
        filter,
        action: {
          setWorkOrders,
          setFilter,
          getWorkOrders,
        },
      }}
    >
      {props.children}
    </WorkOrdersContext.Provider>
  );
};
