import React from "react";
import InsertDesigns from "../../data/insertDesigns.json";

const width10 = {
  width: "10%",
};

const width20 = {
  width: "20%",
};

const FormSaleGlass = (props) => {
  const { newSale, setNewSale } = props;

  // update state passed from prop
  // condition to update nested state
  const handleUpdate = (set, field, value) => {
    if (field === "cost") {
      set((state) => ({
        ...state,
        [field]: value,
      }));
    } else {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorLeft: {
            ...state.productSpecification.door,
            doorInsert: {
              ...state.productSpecification.doorLeft.doorInsert,
              [field]: value,
            },
          },
        },
      }));
    }
  };

  const inputField = (labelName, field, value, type) => {
    return (
      <div>
        <div className="Form-input-container" data-validate="Name is required">
          <span className="Form-label">{labelName}</span>
          <input
            className="Form-input"
            type={type}
            name={field}
            value={value}
            onChange={(event) =>
              handleUpdate(setNewSale, field, event.target.value)
            }
          />
          <span className="Form-input-focus"></span>
        </div>
        <span className="form-error-message">{/* error message */}</span>
      </div>
    );
  };

  return (
    <div>
      <div className="Form-section-1">
        <div className="Form-section-1-input" style={width20}>
          <div
            className="Form-input-container"
            data-validate="Name is required"
          >
            <span className="Form-label">Insert Design</span>
            <input
              className="Form-input"
              type="text"
              list="insertDesigns"
              name="design"
              value={newSale.productSpecification.doorLeft.doorInsert.design}
              onChange={(event) =>
                handleUpdate(setNewSale, "design", event.target.value)
              }
              placeholder="Insert Design"
            />
            <span className="Form-input-focus"></span>
            <datalist id="insertDesigns">
              {InsertDesigns.map((insertDesign) => {
                return <option value={`${insertDesign.design}`} />;
              })}
            </datalist>
          </div>
          <span className="form-error-message">{/* error message */}</span>
        </div>
      </div>

      <h6 style={{ fontWeight: 700 }}>Size</h6>
      <div className="Form-section-1">
        <div className="Form-section-1-input" style={width10}>
          {inputField(
            "Width",
            "width",
            newSale.productSpecification.doorLeft.doorInsert.width,
            "number"
          )}
        </div>
        <div className="Form-section-1-input" style={width10}>
          {inputField(
            "Height",
            "height",
            newSale.productSpecification.doorLeft.doorInsert.height,
            "number"
          )}
        </div>
      </div>

      <div className="Form-section-1">
        <div className="Form-section-1-input" style={width10}>
          {inputField(
            "Quantity",
            "quantity",
            newSale.productSpecification.doorLeft.doorInsert.quantity,
            "number"
          )}
        </div>

        <div className="Form-section-1-input" style={width10}>
          {inputField(
            "Cost",
            "cost",
            newSale.productSpecification.cost,
            "number"
          )}
        </div>
      </div>
    </div>
  );
};

export default FormSaleGlass;
