import React from "react";
import { BrowserRouter } from "react-router-dom";

import { Provider as LoginProvider } from "./context/loginContext";
import { Provider as CustomersProvider } from "./context/customersContext";
import { Provider as SalesProvider } from "./context/salesContext";
import { Provider as WorkOrdersProvider } from "./context/workOrdersContext";
import Routers from "./components/router/Routers";

import "./App.css";

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <LoginProvider>
          <CustomersProvider>
            <SalesProvider>
              <WorkOrdersProvider>
                <Routers />
              </WorkOrdersProvider>
            </SalesProvider>
          </CustomersProvider>
        </LoginProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
