import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

// LOCAL IMPORTS

import FormCustomer from "./FormCustomer";
import FormSale from "./FormSale";
import "./Form.css";

const Form = (props) => {
  const [form, setForm] = useState("formsPage");

  useEffect(() => {
    if (!props.showModal) {
      setTimeout(() => {
        setForm("formsPage");
      }, 200);
    }
  }, [props.showModal]);

  const showForm = () => {
    if (form === "formsPage") {
      return (
        <div className="Form-container">
          <div className="Form-bottom-row">
            <button
              className="Form-card"
              onClick={() => setForm("addCustomer")}
            >
              <p>Add Customer</p>
              <h1>
                <i className="fas fa-user"></i>
              </h1>
            </button>

            <button
              className="Form-card"
              onClick={() => {
                props.setShowModal(false);
                props.setOpen(true);
              }}
            >
              <p>Add Sales</p>
              <h1>
                <i className="fas fa-shopping-cart"></i>
              </h1>
            </button>
          </div>
          {/* <div className="Form-bottom-row">
            <button className="Form-card" onClick={() => setForm("addSale")}>
              <p>Add Sales</p>
              <h1>
                <i className="fas fa-shopping-cart"></i>
              </h1>
            </button>

            <button className="Form-card" onClick={() => setForm("addProduct")}>
              <p>Add Products</p>
              <h1>
                <i className="fas fa-box-open"></i>
              </h1>
            </button>
          </div> */}
        </div>
      );
    } else if (form === "addCustomer") {
      return (
        <div className="Form-container">
          <FormCustomer
            showModal={props.showModal}
            setShowModal={props.setShowModal}
          />
        </div>
      );
    } else if (form === "addSale") {
      return (
        <div className="Form-container">
          <FormSale
            showModal={props.showModal}
            setShowModal={props.setShowModal}
            closeModal={props.setOpen(false)}
          />
        </div>
      );
    }
  };
  return (
    <div className="Form">
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {form !== "formsPage" && (
            <button
              className="btn btn-light"
              onClick={() => setForm("formsPage")}
            >
              Back
            </button>
          )}
        </Modal.Header>
        {showForm()}
      </Modal>
    </div>
  );
};

export default Form;
