import React, { useContext, useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { Spinner } from "react-bootstrap";

import { CustomersContext } from "../../context/customersContext";

import CustomersTable from "../tables/CustomersTable";
import CustomerModal from "../modals/CustomerModal";
import "./Customers.css";

const Customers = () => {
  const { action, customers } = useContext(CustomersContext);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [search, setSearch] = useState("");
  const [currentCustomers, setCurrentCustomers] = useState(customers);
  const [filter, setFilter] = useState("All");

  useEffect(() => {
    action.getCustomersList();
    if (search === "" && filter === "All") {
      setCurrentCustomers(customers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers]);

  useEffect(() => {
    setCurrentCustomers(customers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!customers]);

  // MODAL
  const [open, setOpen] = useState(false);

  const handleClickOpen = async (customerId) => {
    setOpen(true);

    const customer = await action.getCustomer(customerId);
    setSelectedCustomer(customer);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCustomer();
  };

  // SEARCH
  const handleSearch = (event) => {
    event.preventDefault();

    if (event.target.value === "") {
      setSearch("");
      setFilter("All");
      setCurrentCustomers(customers);
    } else {
      setSearch(event.target.value);

      // FIND CUSTOMER WITH SEARCH
      const searchedCustomers = customers.filter((customer) => {
        customer.fullName = `${customer.firstName} ${customer.lastName}`;

        return Object.entries(customer).some(
          (value) =>
            typeof value[1] === "string" &&
            (value[0] === "fullName" ||
              value[0] === "mobile" ||
              value[0] === "city") &&
            value[1].toLowerCase().includes(event.target.value.toLowerCase())
        );
      });

      setCurrentCustomers(searchedCustomers);
    }
  };

  const updateFilter = (event) => {
    const filterValue = event.target.innerHTML;
    setFilter(filterValue);

    if (filterValue === "All") {
      action.getCustomersList();
      setCurrentCustomers(customers);
    } else if (filterValue === "Contractors") {
      const filteredCustomers = customers.filter(
        (customer) => customer.contractor === "Yes"
      );
      setCurrentCustomers(filteredCustomers);
    } else if (filterValue === "Customers") {
      const filteredCustomers = customers.filter(
        (customer) => customer.contractor === "No"
      );
      setCurrentCustomers(filteredCustomers);
    }
  };

  // SET WORKORDERS ARRAY TO ALL BEFORE SEARCHING
  const resetField = (event) => {
    event.preventDefault();
    if (event.target.value === "") {
      setCurrentCustomers(customers);
    }
  };

  const refreshCustomersList = () => {
    setSearch("");
    action.getCustomersList();
    setCurrentCustomers(customers);
  };

  return (
    <div>
      {customers ? (
        <div className="Customers">
          <div className="WorkOrders-searchbar-container">
            <input
              type="search"
              placeholder="Search customer, phone number, or city"
              value={search}
              onChange={handleSearch}
              onFocus={resetField}
            />
          </div>

          <div className="Customers-overview-section">
            <Dropdown>
              <Dropdown.Toggle
                style={{
                  margin: "1rem",
                  backgroundColor: "rgb(146, 200, 235)",
                  fontWeight: "bold",
                }}
                id="dropdown-basic"
              >
                {filter}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={updateFilter}>All</Dropdown.Item>
                <Dropdown.Item onClick={updateFilter}>
                  Contractors
                </Dropdown.Item>
                <Dropdown.Item onClick={updateFilter}>Customers</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div className="Customers-table-section">
            {currentCustomers && (
              <CustomersTable
                handleClickOpen={handleClickOpen}
                customers={currentCustomers}
                refreshCustomersList={refreshCustomersList}
                action={action}
              />
            )}
          </div>

          <CustomerModal
            selectedCustomer={selectedCustomer}
            open={open}
            setOpen={setOpen}
            handleClose={handleClose}
            handleClickOpen={handleClickOpen}
          />
        </div>
      ) : (
        <div className="loading">
          <Spinner
            animation="border"
            role="status"
            style={{ color: " rgb(84, 163, 202)" }}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
};

export default Customers;
