import React, { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";

import { SalesContext } from "../../context/salesContext";
import DoorNoteModal from "../modals/DoorNoteModal";
import DoorStyles from "../../data/doorStyles.json";
import "./SaleDoorRender.css";

const SaleDoorRender = (props) => {
  const { selectedSale } = props;
  const updatedSale = selectedSale;
  const { action } = useContext(SalesContext);
  const {
    frameSize,
    doorLeft,
    doorMiddle,
    doorRight,
    sidelightLeft,
    sidelightRight,
    transom,
  } = selectedSale.productSpecification;

  const doorNotes = selectedSale.productSpecification.doorNotes || [];
  const [scrollPosition, setScrollPosition] = useState(0);

  // SCROLL POSITION
  // ON RENDER
  useEffect(() => {
    if (scrollPosition > 0) {
      window.scrollTo(0, scrollPosition);
    }
  });

  // SCROLL POSITION
  const handleScrollPosition = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  // MODAL
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [deletingNote, setDeletingNote] = useState(false);

  const addNote = async (note) => {
    updatedSale.productSpecification.doorNotes.push(note);

    await action.updateSale(selectedSale._id, updatedSale);
  };

  const removeNote = async (selectedIndex) => {
    setDeletingNote(true);
    const updatedNotes = selectedSale.productSpecification.doorNotes
      .slice(0)
      .reverse()
      .filter((note, index) => index !== selectedIndex)
      .slice(0)
      .reverse();

    updatedSale.productSpecification.doorNotes = updatedNotes;
    await action.updateSale(selectedSale._id, updatedSale);
    setDeletingNote(false);
  };

  const addImportant = async (index) => {
    handleScrollPosition();

    setDeletingNote(true);
    if (!updatedSale.productSpecification.doorNotes[index].important) {
      updatedSale.productSpecification.doorNotes[index].important = true;
    } else {
      updatedSale.productSpecification.doorNotes[index].important = false;
    }

    await action.updateSale(selectedSale._id, updatedSale);
    setDeletingNote(false);
  };

  const moveUpNote = async (index) => {
    handleScrollPosition();

    if (index > 0) {
      setDeletingNote(true);
      let note = updatedSale.productSpecification.doorNotes[index];

      updatedSale.productSpecification.doorNotes.splice(index, 1);
      updatedSale.productSpecification.doorNotes.splice(index - 1, 0, note);

      await action.updateSale(selectedSale._id, updatedSale);

      setDeletingNote(false);
    }
  };

  const moveDownNote = async (index) => {
    handleScrollPosition();

    if (index < updatedSale.productSpecification.doorNotes.length - 1) {
      setDeletingNote(true);
      let note = updatedSale.productSpecification.doorNotes[index];

      updatedSale.productSpecification.doorNotes.splice(index, 1);
      updatedSale.productSpecification.doorNotes.splice(index + 1, 0, note);

      await action.updateSale(selectedSale._id, updatedSale);

      setDeletingNote(false);
    }
  };

  // MEASUREMENTS

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [scale, setScale] = useState(7);

  window.addEventListener("resize", function () {
    setWindowWidth(window.innerWidth);
  });

  useEffect(() => {
    if (windowWidth > 1024) {
      setScale(7);
    } else if (windowWidth < 1024) {
      setScale(5);
    }
  }, [windowWidth]);

  const calcSidelightSize = () => {
    let width =
      frameSize.width -
      doorLeft.doorSlabWidth -
      doorMiddle.doorSlabWidth -
      doorRight.doorSlabWidth;

    width -= getJambWidth();
    if (sidelightRight.sidelight === "Yes" && sidelightLeft.side === "Yes") {
      return width / 2;
    } else {
      return width;
    }
  };

  const getJambWidth = () => {
    let jambWidth = 0;
    if (doorLeft.doorSlabWidth > 0) {
      jambWidth += 1.5;
    }
    if (doorMiddle.doorSlabWidth > 0) {
      jambWidth += 1.5;
    }
    if (doorRight.doorSlabWidth > 0) {
      jambWidth += 1.5;
    }

    if (sidelightLeft.sidelight === "Yes") {
      jambWidth += 1.5;
    }

    if (sidelightRight.sidelight === "Yes") {
      jambWidth += 1.5;
    }

    return jambWidth;
  };

  // RENDER BOXES
  const renderTransomBox = () => {
    if (transom.transomBox === "Yes") {
      return (
        <div
          className="transomStyle"
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: `${(frameSize.width - getJambWidth()) * scale}px`,
            height: `${(frameSize.height - doorLeft.doorSlabHeight) * scale}px`,
            fontWeight: "bold",
            color: "rgb(84, 163, 202)",
          }}
        >
          {transomInsert()}
          {/* <div
            style={{
              position: "absolute",
              bottom: 0,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {action.getFraction(frameSize.height - 82.25)}"{" "}x{" "}
            {action.getFraction(frameSize.width)}"
          </div> */}
        </div>
      );
    }
  };

  const renderDoorBox = (doorSide) => {
    if (doorSide.doorMaterial !== "") {
      return (
        <div>
          <div
            className="doorStyle"
            style={{
              width: `${doorSide.doorSlabWidth * scale}px`,
              height: `${doorSide.doorSlabHeight * scale}px`,
            }}
          >
            {doorSide.hinge.position === "Left"
              ? hinge(doorSide)
              : lock(doorSide)}

            {doorStyle(doorSide)}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                marginLeft: "auto",
                marginRight: "auto",
                fontWeight: "bold",
                color: "rgb(84, 163, 202)",
              }}
            >
              <div
                className="SaleDoorRender-image-text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {doorSide.doorStyle}
              </div>
              <div className="SaleDoorRender-measurements">
                {action.getFraction(doorSide.doorSlabWidth)}"
              </div>
            </div>
            {doorSide.hinge.position === "Right"
              ? hinge(doorSide)
              : lock(doorSide)}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "bold",
            }}
          >
            {doorSide.open === "Yes" && "Door Open"}
          </div>
        </div>
      );
    }
  };

  const renderSidelightBox = (sidelightSide) => {
    if (sidelightSide.sidelight === "Yes") {
      if (
        sidelightSide.panel == 0 ||
        sidelightSide.panel === "" ||
        sidelightSide.panel == 1
      ) {
        return (
          <div
            className="sidelightStyle"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              width: `${calcSidelightSize() * scale}px`,
              height: `${doorLeft.doorSlabHeight * scale}px`,
              fontWeight: "bold",
              color: "rgb(84, 163, 202)",
            }}
          >
            {sidelightInsert(sidelightSide)}
            {sidelightSide.panelWidth && (
              <p className="SaleDoorRender-measurements">
                {action.getFraction(sidelightSide.panelWidth)}" x{" "}
                {action.getFraction(sidelightSide.panelHeight)}"
              </p>
            )}
          </div>
        );
      } else if (sidelightSide.panel == 2) {
        return (
          <div
            className="sidelightStyle"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "stretch",
              alignItems: "center",
              width: `${calcSidelightSize() * scale}px`,
              height: `${doorLeft.doorSlabHeight * scale}px`,
              fontWeight: "bold",
              color: "rgb(84, 163, 202)",
            }}
          >
            <div
              className="sidelightStyle-top-panel"
              style={{
                height: `${sidelightSide.panelHeight2 * scale}px`,
              }}
            >
              {sidelightSide.panelWidth2 && (
                <p className="SaleDoorRender-measurements">
                  {action.getFraction(sidelightSide.panelWidth2)}" x{" "}
                  {action.getFraction(sidelightSide.panelHeight2)}"
                </p>
              )}
            </div>
            <div
              className="sidelightStyle-insert"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "4px solid black",
                height: `${sidelightSide.insertHeight * scale}px`,
              }}
            >
              <p className="SaleDoorRender-measurements">
                {action.getFraction(sidelightSide.insertWidth)}" x{" "}
                {action.getFraction(sidelightSide.insertHeight)}"
              </p>
            </div>
            <div
              className="sidelightStyle-bottom-panel"
              style={{
                height: `${sidelightSide.panelHeight * scale}px`,
              }}
            >
              {sidelightSide.panelWidth && (
                <p className="SaleDoorRender-measurements">
                  {action.getFraction(sidelightSide.panelWidth)}" x{" "}
                  {action.getFraction(sidelightSide.panelHeight)}"
                </p>
              )}
            </div>
          </div>
        );
      }
    }
  };

  // RENDER HINGE AND LOCK
  const hinge = (doorSide) => {
    const doorHinge = {
      height: "25px",
      width: "6px",
      border: "1px solid black",
    };

    return (
      <div>
        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {doorSide.hinge.color}
        </div>
        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: "column",
            top: 0,
            left: doorSide.hinge.position === "Left" && 0,
            right: doorSide.hinge.position === "Right" && 0,
            width: "6px",
            height: doorSide.doorSlabHeight * scale,
          }}
        >
          <div style={doorHinge}></div>
          <div style={doorHinge}></div>
          <div style={doorHinge}></div>
          {doorSide.doorSlabHeight > 85 && <div style={doorHinge}></div>}
        </div>
      </div>
    );
  };

  const lock = (doorSide) => {
    const topBore = {
      marginBottom: "15px",
      height: "15px",
      width: "15px",
      borderRadius: "50%",
      border: "1px solid black",
    };

    const bottomBore = {
      marginBottom: "15px",
      height: "20px",
      width: "20px",
      borderRadius: "50%",
      border: "2px solid black",
    };

    const smallBore = {
      marginBottom: "15px",
      marginLeft: "5px",
      marginRight: "5px",
      height: "13px",
      width: "13px",
      borderRadius: "50%",
      border: "1px solid black",
    };

    const largeBore = {
      marginBottom: "15px",
      height: "25px",
      width: "25px",
      borderRadius: "50%",
      border: "1px solid black",
    };

    const multipoint = {
      height: "25px",
      width: "12px",
      border: "1px solid black",
    };

    const multipointMain = {
      height: "35px",
      width: "20px",
      border: "1px solid black",
    };

    const pullBar = {
      marginBottom: "15px",
      marginLeft: `${0 * scale}px`,
      marginRight: `${0 * scale}px`,
      height: `${48 * scale}px`,
      width: `${1 * scale}px`,
      border: "1px solid black",
    };

    const renderBore = () => {
      // None
      if (doorSide.lock.lockType === "None") {
        return <div></div>;
      } else if (doorSide.lock.lockType === "Single Bore") {
        // Single Bore
        return <div style={topBore}></div>;
      } else if (doorSide.lock.lockType === "Standard Double Bore") {
        // Standard Double Bore
        return (
          <div
            style={{
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              top: 0,
              left: doorSide.hinge.position === "Right" && "5px",
              right: doorSide.hinge.position === "Left" && "5px",
              width: "30%",
              height: doorSide.doorSlabHeight * scale,
            }}
          >
            <div style={topBore}></div>
            <div style={bottomBore}></div>
          </div>
        );
      } else if (doorSide.lock.lockType === "Triple Bore") {
        // Triple Bore
        return (
          <div
            style={{
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              top: 0,
              left: doorSide.hinge.position === "Right" && "5px",
              right: doorSide.hinge.position === "Left" && "5px",
              width: "30%",
              height: doorSide.doorSlabHeight * scale,
            }}
          >
            <div style={topBore}></div>
            <div style={topBore}></div>
            <div style={bottomBore}></div>
          </div>
        );
      } else if (doorSide.lock.lockType === "Multipoint Lock") {
        // Multipoint Lock
        return (
          <div
            style={{
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
              top: 0,
              left: doorSide.hinge.position === "Right" && 0,
              right: doorSide.hinge.position === "Left" && 0,
              width: "30%",
              height: doorSide.doorSlabHeight * scale,
            }}
          >
            <div style={multipoint}></div>
            <div style={multipointMain}></div>
            <div style={multipoint}></div>
          </div>
        );
      } else if (doorSide.lock.lockType === "Two Large Bores") {
        // Two Large Bores
        return (
          <div
            style={{
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              top: 0,
              left: doorSide.hinge.position === "Right" && "5px",
              right: doorSide.hinge.position === "Left" && "5px",
              width: "30%",
              height: doorSide.doorSlabHeight * scale,
            }}
          >
            <div style={largeBore}></div>
            <div style={largeBore}></div>
          </div>
        );
      } else if (doorSide.lock.lockType === "Pull Bar") {
        // Pull Bar
        const lockSide = () => {
          if (doorSide.hinge.position === "Left") {
            return (
              <div
                style={{
                  position: "absolute",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  top: 0,
                  left: doorSide.hinge.position === "Right" && 0,
                  right: doorSide.hinge.position === "Left" && 0,
                  height: doorSide.doorSlabHeight * scale,
                }}
              >
                <div style={pullBar}></div>
                <div style={smallBore}></div>
              </div>
            );
          } else if (doorSide.hinge.position === "Right") {
            return (
              <div
                style={{
                  position: "absolute",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  top: 0,
                  left: doorSide.hinge.position === "Right" && 0,
                  right: doorSide.hinge.position === "Left" && 0,
                  height: doorSide.doorSlabHeight * scale,
                }}
              >
                <div style={smallBore}></div>
                <div style={pullBar}></div>
              </div>
            );
          }
        };

        return (
          <div
            style={{
              position: "absolute",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              top: 0,
              left: doorSide.hinge.position === "Right" && "5px",
              right: doorSide.hinge.position === "Left" && "5px",
              width: "35px",
              height: doorSide.doorSlabHeight * scale,
            }}
          >
            {lockSide()}
          </div>
        );
      } else if (doorSide.lock.lockType === "Custom") {
        // Custom
        return <div className="SaleDoorRender-image-red-text">* C</div>;
      }
    };

    return (
      <div
        style={{
          position: "absolute",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          top: 0,
          left: doorSide.hinge.position === "Right" && "5px",
          right: doorSide.hinge.position === "Left" && "5px",
          width: "20px",
          height: doorSide.doorSlabHeight * scale,
        }}
      >
        {renderBore()}
      </div>
    );
  };

  // RENDER DOOR SLAB
  const doorStyle = (doorSide) => {
    // Styles
    const doorInsert = {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "center",
      position:
        doorSide.doorInsert.position !== "Center" ? "absolute" : "relative",
      marginTop: `${5 * scale}px`,
      right:
        doorSide.hinge.position === "Left" &&
        `${parseInt(doorSide.doorInsert.position) * scale}px`,
      left:
        doorSide.hinge.position === "Right" &&
        `${parseInt(doorSide.doorInsert.position) * scale}px`,
      width: doorSide.doorInsert.width * scale,
      height: doorSide.doorInsert.height * scale,
      border: "4px solid black",
      fontWeight: "bold",
      color: "rgb(84, 163, 202)",
    };

    // Door inserts
    const doorInserts = () => {
      if (
        doorSide.doorInsert.quantity == 1 ||
        doorSide.doorInsert.quantity === ""
      ) {
        return (
          <div style={doorInsert}>
            <div
              className="SaleDoorRender-image-text"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {doorSide.doorInsert.design}
            </div>
            <div>
              <p className="SaleDoorRender-measurements">
                {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                {action.getFraction(doorSide.doorInsert.height)}"
              </p>
            </div>
          </div>
        );
      } else if (doorSide.doorInsert.quantity > 1) {
        let divs = [];
        for (let i = 0; i < doorSide.doorInsert.quantity; i++) {
          divs.push(
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "auto",
                marginBottom: "auto",
                position:
                  doorSide.doorInsert.position !== "Center"
                    ? "absolute"
                    : "relative",

                right:
                  doorSide.hinge.position === "Left" &&
                  `${parseInt(doorSide.doorInsert.position) * scale}px`,
                left:
                  doorSide.hinge.position === "Right" &&
                  `${parseInt(doorSide.doorInsert.position) * scale}px`,
                width: doorSide.doorInsert.width * scale,
                height: doorSide.doorInsert.height * scale,
                border: "4px solid black",
                fontWeight: "bold",
                color: "rgb(84, 163, 202)",
              }}
            >
              <div
                className="SaleDoorRender-image-text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {doorSide.doorInsert.design}
              </div>
              <div>
                <p className="SaleDoorRender-measurements">
                  {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                  {action.getFraction(doorSide.doorInsert.height)}"
                </p>
              </div>
            </div>
          );
        }

        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {divs}
          </div>
        );
      }
    };

    // Dusco 3 panel
    const _3P = () => {
      const topPanel = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: `${5 * scale}px`,
        width:
          doorSide.doorInsert.doorInsert === "Yes"
            ? doorSide.doorInsert.width * scale
            : 22 * scale,

        height: `${17 * scale}px`,
        backgroundColor: "#A2A2A2",
      };

      const middlePanel = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: `${5 * scale}px`,
        width:
          doorSide.doorInsert.doorInsert === "Yes"
            ? doorSide.doorInsert.width * scale
            : 22 * scale,

        height: `${17 * scale}px`,
        backgroundColor: "#A2A2A2",
      };

      const bottomPanel = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: `${5 * scale}px`,
        width:
          doorSide.doorInsert.doorInsert === "Yes"
            ? doorSide.doorInsert.width * scale
            : 22 * scale,

        height: `${17 * scale}px`,
        backgroundColor: "#A2A2A2",
      };

      if (doorSide.doorInsert.doorInsert !== "Yes") {
        // Door style no inserts
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={topPanel}></div>
            <div style={middlePanel}></div>
            <div style={bottomPanel}></div>
          </div>
        );
      } else {
        // Door style with insert
        if (
          doorSide.doorInsert.quantity === "" ||
          doorSide.doorInsert.quantity == 1
        ) {
          if (doorSide.doorInsert.height == 17) {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={doorInsert}>
                  <div
                    className="SaleDoorRender-image-text"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {doorSide.doorInsert.design}
                  </div>
                  <div>
                    <p className="SaleDoorRender-measurements">
                      {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                      {action.getFraction(doorSide.doorInsert.height)}"
                    </p>
                  </div>
                </div>
                <div style={middlePanel}></div>
                <div style={bottomPanel}></div>
              </div>
            );
          } else {
            return (
              <div style={doorInsert}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <p
                    className="SaleDoorRender-image-red-text"
                    style={{ whiteSpace: "normal", textAlign: "center" }}
                  >
                    Error: please check door style and insert size. Door insert
                    for this style must be 17"
                  </p>
                </div>

                <div
                  className="SaleDoorRender-image-text"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {doorSide.doorInsert.design}
                </div>
                <div>
                  <p className="SaleDoorRender-measurements">
                    {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                    {action.getFraction(doorSide.doorInsert.height)}"
                  </p>
                </div>
              </div>
            );
          }
        } else if (doorSide.doorInsert.quantity == 2) {
          if (doorSide.doorInsert.height == 17) {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={doorInsert}>
                  <div
                    className="SaleDoorRender-image-text"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {doorSide.doorInsert.design}
                  </div>
                  <div>
                    <p className="SaleDoorRender-measurements">
                      {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                      {action.getFraction(doorSide.doorInsert.height)}"
                    </p>
                  </div>
                </div>
                <div style={doorInsert}>
                  <div
                    className="SaleDoorRender-image-text"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {doorSide.doorInsert.design}
                  </div>
                  <div>
                    <p className="SaleDoorRender-measurements">
                      {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                      {action.getFraction(doorSide.doorInsert.height)}"
                    </p>
                  </div>
                </div>
                <div style={bottomPanel}></div>
              </div>
            );
          } else {
            return (
              <div>
                <div style={doorInsert}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      className="SaleDoorRender-image-red-text"
                      style={{ whiteSpace: "normal", textAlign: "center" }}
                    >
                      Error: please check door style and insert size. Door
                      insert for this style must be 17"
                    </p>
                  </div>

                  <div
                    className="SaleDoorRender-image-text"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {doorSide.doorInsert.design}
                  </div>
                  <div>
                    <p className="SaleDoorRender-measurements">
                      {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                      {action.getFraction(doorSide.doorInsert.height)}"
                    </p>
                  </div>
                </div>
                <div style={doorInsert}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></div>

                  <div
                    className="SaleDoorRender-image-text"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {doorSide.doorInsert.design}
                  </div>
                  <div>
                    <p className="SaleDoorRender-measurements">
                      {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                      {action.getFraction(doorSide.doorInsert.height)}"
                    </p>
                  </div>
                </div>
              </div>
            );
          }
        } else if (doorSide.doorInsert.quantity == 3) {
          if (doorSide.doorInsert.height == 17) {
            return (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div style={doorInsert}>
                  <div
                    className="SaleDoorRender-image-text"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {doorSide.doorInsert.design}
                  </div>
                  <div>
                    <p className="SaleDoorRender-measurements">
                      {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                      {action.getFraction(doorSide.doorInsert.height)}"
                    </p>
                  </div>
                </div>
                <div style={middlePanel}></div>
                <div style={bottomPanel}></div>
              </div>
            );
          } else {
            return (
              <div>
                <div style={doorInsert}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <p
                      className="SaleDoorRender-image-red-text"
                      style={{ whiteSpace: "normal", textAlign: "center" }}
                    >
                      Error: please check door style and insert size. Door
                      insert for this style must be 17"
                    </p>
                  </div>

                  <div
                    className="SaleDoorRender-image-text"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {doorSide.doorInsert.design}
                  </div>
                  <div>
                    <p className="SaleDoorRender-measurements">
                      {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                      {action.getFraction(doorSide.doorInsert.height)}"
                    </p>
                  </div>
                </div>

                <div style={doorInsert}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></div>

                  <div
                    className="SaleDoorRender-image-text"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {doorSide.doorInsert.design}
                  </div>
                  <div>
                    <p className="SaleDoorRender-measurements">
                      {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                      {action.getFraction(doorSide.doorInsert.height)}"
                    </p>
                  </div>
                </div>

                <div style={doorInsert}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  ></div>

                  <div
                    className="SaleDoorRender-image-text"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {doorSide.doorInsert.design}
                  </div>
                  <div>
                    <p className="SaleDoorRender-measurements">
                      {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                      {action.getFraction(doorSide.doorInsert.height)}"
                    </p>
                  </div>
                </div>
              </div>
            );
          }
        }
      }
    };

    // Dusco Three Panel (With 22x14-7/16" top panel)
    const A = () => {
      const topPanel = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: `${5 * scale}px`,
        width:
          doorSide.doorInsert.doorInsert === "Yes"
            ? doorSide.doorInsert.width * scale
            : 22 * scale,
        height: `${14 * scale}px`,
        backgroundColor: "#A2A2A2",
      };

      const bottomPanel = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: `${5 * scale}px`,
        width:
          doorSide.doorInsert.doorInsert === "Yes"
            ? (doorSide.doorInsert.width / 2 - 2) * scale
            : (22 / 2 - 2) * scale,
        height: `${44 * scale}px`,
        backgroundColor: "#A2A2A2",
      };

      if (doorSide.doorInsert.doorInsert !== "Yes") {
        // Door style with inserts
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width:
                  doorSide.doorInsert.doorInsert === "Yes"
                    ? doorSide.doorInsert.width * scale
                    : 22 * scale,
              }}
            >
              <div style={topPanel}></div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width:
                  doorSide.doorInsert.doorInsert === "Yes"
                    ? doorSide.doorInsert.width * scale
                    : 22 * scale,
              }}
            >
              <div style={bottomPanel}></div>
              <div style={bottomPanel}></div>
            </div>
          </div>
        );
      } else {
        // Door style with door insert
        if (parseFloat(doorSide.doorInsert.height) <= 15) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                alignItems: "center",
              }}
            >
              <div style={doorInsert}>
                <div
                  className="SaleDoorRender-image-text"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {doorSide.doorInsert.design}
                </div>
                <div>
                  <p className="SaleDoorRender-measurements">
                    {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                    {action.getFraction(doorSide.doorInsert.height)}"
                  </p>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: doorSide.doorInsert.width * scale,
                }}
              >
                <div style={bottomPanel}></div>
                <div style={bottomPanel}></div>
              </div>
            </div>
          );
        } else {
          return (
            <div style={doorInsert}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  className="SaleDoorRender-image-red-text"
                  style={{ whiteSpace: "normal", textAlign: "center" }}
                >
                  Error: please check door style and insert size. Door insert
                  for this style must be 14-7/16"
                </p>
              </div>

              <div
                className="SaleDoorRender-image-text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {doorSide.doorInsert.design}
              </div>
              <div>
                <p className="SaleDoorRender-measurements">
                  {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                  {action.getFraction(doorSide.doorInsert.height)}"
                </p>
              </div>
            </div>
          );
        }
      }
    };

    // Dusco flush
    const P = () => {
      if (doorSide.doorInsert.doorInsert === "Yes") {
        return doorInserts();
      } else {
        return <div></div>;
      }
    };

    // Dusco 2 panel
    const B = () => {
      const topPanel = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: `${5 * scale}px`,
        width:
          doorSide.doorInsert.doorInsert === "Yes"
            ? doorSide.doorInsert.width * scale
            : 22 * scale,

        height: `${36 * scale}px`,
        backgroundColor: "#A2A2A2",
      };

      const bottomPanel = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: `${5 * scale}px`,
        width:
          doorSide.doorInsert.doorInsert === "Yes"
            ? doorSide.doorInsert.width * scale
            : 22 * scale,

        height: `${23 * scale}px`,
        backgroundColor: "#A2A2A2",
      };

      if (doorSide.doorInsert.doorInsert !== "Yes") {
        // Door style no inserts
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={topPanel}></div>
            <div style={bottomPanel}></div>
          </div>
        );
      } else {
        // Door style with insert
        if (doorSide.doorInsert.height == 36) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={doorInsert}>
                <div
                  className="SaleDoorRender-image-text"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {doorSide.doorInsert.design}
                </div>
                <div>
                  <p className="SaleDoorRender-measurements">
                    {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                    {action.getFraction(doorSide.doorInsert.height)}"
                  </p>
                </div>
              </div>
              <div style={bottomPanel}></div>
            </div>
          );
        } else if (doorSide.doorInsert.height == 64) {
          return (
            <div style={doorInsert}>
              <div
                className="SaleDoorRender-image-text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {doorSide.doorInsert.design}
              </div>
              <div
                className="SaleDoorRender-image-text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {doorSide.doorInsert.design}
              </div>
              <div>
                <p className="SaleDoorRender-measurements">
                  {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                  {action.getFraction(doorSide.doorInsert.height)}"
                </p>
              </div>
            </div>
          );
        } else {
          return (
            <div style={doorInsert}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  className="SaleDoorRender-image-red-text"
                  style={{ whiteSpace: "normal", textAlign: "center" }}
                >
                  Error: please check door style and insert size. Door insert
                  for this style must be 36" or 64"
                </p>
              </div>

              <div
                className="SaleDoorRender-image-text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {doorSide.doorInsert.design}
              </div>
              <div>
                <p className="SaleDoorRender-measurements">
                  {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                  {action.getFraction(doorSide.doorInsert.height)}"
                </p>
              </div>
            </div>
          );
        }
      }
    };

    // Dusco two panel narrow
    const BN = () => {
      const topPanel = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: `${5 * scale}px`,
        width: doorSide.doorInsert.width * scale,
        width:
          doorSide.doorInsert.doorInsert === "Yes"
            ? doorSide.doorInsert.width * scale
            : 22 * scale,
        height: `${48 * scale}px`,
        backgroundColor: "#A2A2A2",
      };

      const bottomPanel = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: `${5 * scale}px`,
        width:
          doorSide.doorInsert.doorInsert === "Yes"
            ? doorSide.doorInsert.width * scale
            : 22 * scale,

        height: `${11 * scale}px`,
        backgroundColor: "#A2A2A2",
      };

      if (doorSide.doorInsert.doorInsert !== "Yes") {
        // Door style with inserts
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={topPanel}></div>
            <div style={bottomPanel}></div>
          </div>
        );
      } else {
        // Door style with no door insert
        if (doorSide.doorInsert.height == 48) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                alignItems: "center",
              }}
            >
              <div style={doorInsert}>
                <div
                  className="SaleDoorRender-image-text"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {doorSide.doorInsert.design}
                </div>
                <div>
                  <p className="SaleDoorRender-measurements">
                    {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                    {action.getFraction(doorSide.doorInsert.height)}"
                  </p>
                </div>
              </div>
              <div style={bottomPanel}></div>
            </div>
          );
        } else if (doorSide.doorInsert.height == 64) {
          return (
            <div style={doorInsert}>
              <div
                className="SaleDoorRender-image-text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {doorSide.doorInsert.design}
              </div>
              <div>
                <p className="SaleDoorRender-measurements">
                  {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                  {action.getFraction(doorSide.doorInsert.height)}"
                </p>
              </div>
            </div>
          );
        } else {
          return (
            <div style={doorInsert}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  className="SaleDoorRender-image-red-text"
                  style={{ whiteSpace: "normal", textAlign: "center" }}
                >
                  Error: please check door style and insert size. Door insert
                  for this style must be 48" or 64"
                </p>
              </div>

              <div
                className="SaleDoorRender-image-text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {doorSide.doorInsert.design}
              </div>
              <div>
                <p className="SaleDoorRender-measurements">
                  {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                  {action.getFraction(doorSide.doorInsert.height)}"
                </p>
              </div>
            </div>
          );
        }
      }
    };

    // Dusco 3 panel scroll top
    const D = () => {
      const scrollTop = {
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        top: `${5 * scale}px`,
        width:
          doorSide.doorInsert.doorInsert === "Yes"
            ? (doorSide.doorInsert.width - 5) * scale
            : (22 - 5) * scale,
        height: `${5 * scale}px`,
        backgroundColor: "#A2A2A2",
        borderTopLeftRadius: "50%",
        borderTopRightRadius: "50%",
      };

      const topPanel = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: `${7 * scale}px`,
        width:
          doorSide.doorInsert.doorInsert === "Yes"
            ? doorSide.doorInsert.width * scale
            : 22 * scale,
        height: `${46 * scale}px`,
        backgroundColor: "#A2A2A2",
      };

      const bottomPanel = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: `${5 * scale}px`,
        width:
          doorSide.doorInsert.doorInsert === "Yes"
            ? (doorSide.doorInsert.width / 2 - 2) * scale
            : (22 / 2 - 2) * scale,
        height: `${11 * scale}px`,
        backgroundColor: "#A2A2A2",
      };

      if (doorSide.doorInsert.doorInsert !== "Yes") {
        // Door style with inserts
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {" "}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width:
                  doorSide.doorInsert.doorInsert === "Yes"
                    ? doorSide.doorInsert.width * scale
                    : 22 * scale,
              }}
            >
              <div style={scrollTop}></div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width:
                  doorSide.doorInsert.doorInsert === "Yes"
                    ? doorSide.doorInsert.width * scale
                    : 22 * scale,
              }}
            >
              <div style={topPanel}></div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width:
                  doorSide.doorInsert.doorInsert === "Yes"
                    ? doorSide.doorInsert.width * scale
                    : 22 * scale,
              }}
            >
              <div style={bottomPanel}></div>
              <div style={bottomPanel}></div>
            </div>
          </div>
        );
      } else {
        // Door style with door insert
        if (doorSide.doorInsert.height == 36) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                alignItems: "center",
              }}
            >
              <div style={doorInsert}>
                <div
                  className="SaleDoorRender-image-text"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {doorSide.doorInsert.design}
                </div>
                <div>
                  <p className="SaleDoorRender-measurements">
                    {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                    {action.getFraction(doorSide.doorInsert.height)}"
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: doorSide.doorInsert.width * scale,
                }}
              >
                <div style={bottomPanel}></div>
                <div style={bottomPanel}></div>
              </div>
            </div>
          );
        } else if (doorSide.doorInsert.height == 64) {
          return (
            <div style={doorInsert}>
              <div
                className="SaleDoorRender-image-text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {doorSide.doorInsert.design}
              </div>
              <div>
                <p className="SaleDoorRender-measurements">
                  {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                  {action.getFraction(doorSide.doorInsert.height)}"
                </p>
              </div>
            </div>
          );
        } else {
          return (
            <div style={doorInsert}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  className="SaleDoorRender-image-red-text"
                  style={{ whiteSpace: "normal", textAlign: "center" }}
                >
                  Error: please check door style and insert size. Door insert
                  for this style must be 36" or 64"
                </p>
              </div>

              <div
                className="SaleDoorRender-image-text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {doorSide.doorInsert.design}
              </div>
              <div>
                <p className="SaleDoorRender-measurements">
                  {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                  {action.getFraction(doorSide.doorInsert.height)}"
                </p>
              </div>
            </div>
          );
        }
      }
    };

    // Dusco Four Panel
    const F = () => {
      const topPanel = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop:
          doorSide.doorInsert.doorInsert === "Yes"
            ? `${(18 - doorSide.doorInsert.height) * scale}px`
            : `${18 * scale}px`,
        width:
          doorSide.doorInsert.doorInsert === "Yes"
            ? (doorSide.doorInsert.width / 2 - 2) * scale
            : (22 / 2 - 2) * scale,
        height: `${23 * scale}px`,
        backgroundColor: "#A2A2A2",
      };

      const bottomPanel = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: `${5 * scale}px`,
        width:
          doorSide.doorInsert.doorInsert === "Yes"
            ? (doorSide.doorInsert.width / 2 - 2) * scale
            : (22 / 2 - 2) * scale,
        height: `${23 * scale}px`,
        backgroundColor: "#A2A2A2",
      };

      if (doorSide.doorInsert.doorInsert !== "Yes") {
        // Door style with inserts
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width:
                  doorSide.doorInsert.doorInsert === "Yes"
                    ? doorSide.doorInsert.width * scale
                    : 22 * scale,
              }}
            >
              <div style={topPanel}></div>
              <div style={topPanel}></div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width:
                  doorSide.doorInsert.doorInsert === "Yes"
                    ? doorSide.doorInsert.width * scale
                    : 22 * scale,
              }}
            >
              <div style={bottomPanel}></div>
              <div style={bottomPanel}></div>
            </div>
          </div>
        );
      } else {
        // Door style with door insert
        if (doorSide.doorInsert.height <= 10) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                alignItems: "center",
              }}
            >
              <div style={doorInsert}>
                <div
                  className="SaleDoorRender-image-text"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {doorSide.doorInsert.design}
                </div>
                <div>
                  <p className="SaleDoorRender-measurements">
                    {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                    {action.getFraction(doorSide.doorInsert.height)}"
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width:
                    doorSide.doorInsert.doorInsert === "Yes"
                      ? doorSide.doorInsert.width * scale
                      : 22 * scale,
                }}
              >
                <div style={topPanel}></div>
                <div style={topPanel}></div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: doorSide.doorInsert.width * scale,
                }}
              >
                <div style={bottomPanel}></div>
                <div style={bottomPanel}></div>
              </div>
            </div>
          );
        } else if (doorSide.doorInsert.height == 36) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                alignItems: "center",
              }}
            >
              <div style={doorInsert}>
                <div
                  className="SaleDoorRender-image-text"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {doorSide.doorInsert.design}
                </div>
                <div>
                  <p className="SaleDoorRender-measurements">
                    {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                    {action.getFraction(doorSide.doorInsert.height)}"
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: doorSide.doorInsert.width * scale,
                }}
              >
                <div style={bottomPanel}></div>
                <div style={bottomPanel}></div>
              </div>
            </div>
          );
        } else if (doorSide.doorInsert.height == 64) {
          return (
            <div style={doorInsert}>
              <div
                className="SaleDoorRender-image-text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {doorSide.doorInsert.design}
              </div>
              <div>
                <p className="SaleDoorRender-measurements">
                  {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                  {action.getFraction(doorSide.doorInsert.height)}"
                </p>
              </div>
            </div>
          );
        } else {
          return (
            <div style={doorInsert}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  className="SaleDoorRender-image-red-text"
                  style={{ whiteSpace: "normal", textAlign: "center" }}
                >
                  Error: please check door style and insert size. Door insert
                  for this style must be 6", 8", 10", 36" or 64"
                </p>
              </div>

              <div
                className="SaleDoorRender-image-text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {doorSide.doorInsert.design}
              </div>
              <div>
                <p className="SaleDoorRender-measurements">
                  {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                  {action.getFraction(doorSide.doorInsert.height)}"
                </p>
              </div>
            </div>
          );
        }
      }
    };

    // Dusco six panel
    const S = () => {
      const topPanel = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: `${5 * scale}px`,
        width:
          doorSide.doorInsert.doorInsert === "Yes"
            ? (doorSide.doorInsert.width / 2 - 2) * scale
            : (22 / 2 - 2) * scale,
        height: `${8 * scale}px`,
        backgroundColor: "#A2A2A2",
      };

      const middlePanel = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: `${5 * scale}px`,
        width:
          doorSide.doorInsert.doorInsert === "Yes"
            ? (doorSide.doorInsert.width / 2 - 2) * scale
            : (22 / 2 - 2) * scale,
        height: `${23 * scale}px`,
        backgroundColor: "#A2A2A2",
      };

      const bottomPanel = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: `${5 * scale}px`,
        width:
          doorSide.doorInsert.doorInsert === "Yes"
            ? (doorSide.doorInsert.width / 2 - 2) * scale
            : (22 / 2 - 2) * scale,
        height: `${23 * scale}px`,
        backgroundColor: "#A2A2A2",
      };

      if (doorSide.doorInsert.doorInsert !== "Yes") {
        // Door style with inserts
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width:
                  doorSide.doorInsert.doorInsert === "Yes"
                    ? doorSide.doorInsert.width * scale
                    : 22 * scale,
              }}
            >
              <div style={topPanel}></div>
              <div style={topPanel}></div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width:
                  doorSide.doorInsert.doorInsert === "Yes"
                    ? doorSide.doorInsert.width * scale
                    : 22 * scale,
              }}
            >
              <div style={middlePanel}></div>
              <div style={middlePanel}></div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width:
                  doorSide.doorInsert.doorInsert === "Yes"
                    ? doorSide.doorInsert.width * scale
                    : 22 * scale,
              }}
            >
              <div style={bottomPanel}></div>
              <div style={bottomPanel}></div>
            </div>
          </div>
        );
      } else {
        // Door style with door insert
        if (doorSide.doorInsert.height == 10) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                alignItems: "center",
              }}
            >
              <div style={doorInsert}>
                <div
                  className="SaleDoorRender-image-text"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {doorSide.doorInsert.design}
                </div>
                <div>
                  <p className="SaleDoorRender-measurements">
                    {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                    {action.getFraction(doorSide.doorInsert.height)}"
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width:
                    doorSide.doorInsert.doorInsert === "Yes"
                      ? doorSide.doorInsert.width * scale
                      : 22 * scale,
                }}
              >
                <div style={middlePanel}></div>
                <div style={middlePanel}></div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: doorSide.doorInsert.width * scale,
                }}
              >
                <div style={bottomPanel}></div>
                <div style={bottomPanel}></div>
              </div>
            </div>
          );
        }
        if (doorSide.doorInsert.height == 36) {
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                alignItems: "center",
              }}
            >
              <div style={doorInsert}>
                <div
                  className="SaleDoorRender-image-text"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {doorSide.doorInsert.design}
                </div>
                <div>
                  <p className="SaleDoorRender-measurements">
                    {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                    {action.getFraction(doorSide.doorInsert.height)}"
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: doorSide.doorInsert.width * scale,
                }}
              >
                <div style={bottomPanel}></div>
                <div style={bottomPanel}></div>
              </div>
            </div>
          );
        } else if (doorSide.doorInsert.height == 64) {
          return (
            <div style={doorInsert}>
              <div
                className="SaleDoorRender-image-text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {doorSide.doorInsert.design}
              </div>
              <div>
                <p className="SaleDoorRender-measurements">
                  {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                  {action.getFraction(doorSide.doorInsert.height)}"
                </p>
              </div>
            </div>
          );
        } else {
          return (
            <div style={doorInsert}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p
                  className="SaleDoorRender-image-red-text"
                  style={{ whiteSpace: "normal", textAlign: "center" }}
                >
                  Error: please check door style and insert size. Door insert
                  for this style must be 10", 36" or 64"
                </p>
              </div>

              <div
                className="SaleDoorRender-image-text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {doorSide.doorInsert.design}
              </div>
              <div>
                <p className="SaleDoorRender-measurements">
                  {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                  {action.getFraction(doorSide.doorInsert.height)}"
                </p>
              </div>
            </div>
          );
        }
      }
    };

    const renderDoorStyle = (doorStyle) => {
      let style;

      if (
        doorStyle === "_3P" ||
        doorStyle === "A" ||
        doorStyle === "B" ||
        doorStyle === "BN" ||
        doorStyle === "D" ||
        doorStyle === "F" ||
        doorStyle === "P" ||
        doorStyle === "S"
      ) {
        style = doorStyle;
      } else if (doorStyle === "Sydney") {
        style = "_3P";
      } else if (doorStyle === "Sydney") {
        style = "A";
      } else if (
        doorStyle === "London" ||
        doorStyle === "SM-2P" ||
        doorStyle === "WG265" ||
        doorStyle === "SG25" ||
        doorStyle === "SG28"
      ) {
        style = "B";
      } else if (
        doorStyle === "Orleans" ||
        doorStyle === "WG-2EM" ||
        doorStyle === "WG2PR" ||
        doorStyle === "SM-2EM" ||
        doorStyle === "SM-2PR" ||
        doorStyle === "MAH-2EM" ||
        doorStyle === "WG68" ||
        doorStyle === "SG68"
      ) {
        style = "BN";
      } else if (
        doorStyle === "3 Panel Scroll Top" ||
        doorStyle === "MAH-3PA" ||
        doorStyle === "SM-3PA" ||
        doorStyle === "WG-3PA"
      ) {
        style = "D";
      } else if (
        doorStyle === "4 Panel" ||
        doorStyle === "WG-4PBT" ||
        doorStyle === "SM-4PBT" ||
        doorStyle === "WG46" ||
        doorStyle === "SG46"
      ) {
        style = "F";
      } else if (
        doorStyle === "Garden Door" ||
        doorStyle === "N600" ||
        doorStyle === "N900 Flush" ||
        doorStyle === "SM-F" ||
        doorStyle === "WG-F" ||
        doorStyle === "MAH-F" ||
        doorStyle === "WG00" ||
        doorStyle === "SG00"
      ) {
        style = "P";
      } else if (
        doorStyle === "Brixton" ||
        doorStyle === "S-Narrow" ||
        doorStyle === "Six Panel" ||
        doorStyle === "SM-6P" ||
        doorStyle === "WG-6P"
      ) {
        style = "S";
      }

      try {
        return eval(style)();
      } catch (error) {
        if (doorSide.doorInsert.doorInsert === "Yes") {
          // render glass insert
          return doorInserts();
        } else {
          return (
            <div className="SaleDoorRender-image-red-text">
              No door insert or render for this door style!
            </div>
          );
        }
      }
    };

    // render door slab based on door style field
    if (doorSide.doorStyle !== "") {
      let styleExist = DoorStyles.find((dS) => dS.style === doorSide.doorStyle);

      if (styleExist) {
        return renderDoorStyle(doorSide.doorStyle);
      } else {
        if (doorSide.doorInsert.doorInsert === "Yes") {
          return (
            <div style={doorInsert}>
              <div className="SaleDoorRender-image-red-text">
                <p>No door insert or drawing for door style.</p>
                <p>Check door style.</p>
              </div>

              <div
                className="SaleDoorRender-image-text"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {doorSide.doorInsert.design}
              </div>
              <div>
                <p className="SaleDoorRender-measurements">
                  {action.getFraction(doorSide.doorInsert.width)}" x{" "}
                  {action.getFraction(doorSide.doorInsert.height)}"
                </p>
              </div>
            </div>
          );
        } else {
          return (
            <div>
              <div className="SaleDoorRender-image-red-text">
                <p>No door insert or drawing for door style.</p>
                <p>Check door style.</p>
              </div>
            </div>
          );
        }
      }
    } else {
      if (doorSide.doorInsert.doorInsert === "Yes") {
        return doorInserts();
      }
    }
  };

  const sidelightInsert = (sidelightSide) => {
    const sidelightInsertHeight = parseFloat(sidelightSide.insertHeight);

    const sidelightInsert = {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      marginTop: sidelightInsertHeight < 65 && `${5 * scale}px`,
      width: sidelightSide.insertWidth * scale,
      height: sidelightSide.insertHeight * scale,
      border: "4px solid black",
      fontWeight: "bold",
      color: "rgb(84, 163, 202)",
    };

    if (sidelightSide.sidelight === "Yes") {
      return (
        <div style={sidelightInsert}>
          <p className="SaleDoorRender-measurements">
            {action.getFraction(sidelightSide.insertWidth)}" x{" "}
            {action.getFraction(sidelightSide.insertHeight)}"
          </p>
        </div>
      );
    }
  };

  const transomInsert = () => {
    const transomInsert = {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      width: transom.transomInsertWidth * scale,
      height: transom.transomInsertHeight * scale,
      border: "4px solid black",
      fontWeight: "bold",
      color: "rgb(84, 163, 202)",
    };

    if (transom.transomBox === "Yes") {
      return (
        <div style={transomInsert}>
          <p className="SaleDoorRender-measurements">
            {action.getFraction(transom.transomInsertWidth)}" x{" "}
            {action.getFraction(transom.transomInsertHeight)}"
          </p>
        </div>
      );
    }
  };

  return (
    <div className="SaleDoorRender">
      {selectedSale.product === "Door" && (
        <div
          className="frameStyle"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: `${frameSize.width * scale}px`,
            height: `${frameSize.height * scale}px`,
          }}
        >
          <div className="SaleDoorRender-top-section">{renderTransomBox()}</div>
          <div
            className="SaleDoorRender-bottom-section"
            style={{
              width: `${frameSize.width * scale}px`,
              height: `${doorLeft.doorSlabHeight * scale}px`,
            }}
          >
            {renderSidelightBox(sidelightLeft)}
            {renderDoorBox(doorLeft)}
            {renderDoorBox(doorMiddle)}
            {renderDoorBox(doorRight)}
            {renderSidelightBox(sidelightRight)}
          </div>
          <div
            style={{
              marginTop: "2.5rem",
              width: "100%",
              height: "1px",
              marginBottom: "1rem",
              border: "1px solid #f6f6f6",
            }}
          ></div>
          <p
            style={{
              padding: "0.5rem",
              fontWeight: "bold",
              backgroundColor: selectedSale.productSpecification.sill.includes(
                "Black"
              )
                ? "black"
                : "#EAEAEA",
              color: selectedSale.productSpecification.sill.includes("Black")
                ? "white"
                : "black",
              borderRadius: "25px",
            }}
          >
            {selectedSale.productSpecification.sill}{" "}
            {selectedSale.productSpecification.sillExtension &&
              selectedSale.productSpecification.sillExtension !== "1.5" &&
              `- ${selectedSale.productSpecification.sillExtension}" EXT`}
          </p>
        </div>
      )}

      <div className="SaleDoorRender-door-notes">
        <ul>
          {doorNotes.map((note, index) => {
            return (
              <li
                key={index}
                className="payment-card"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontWeight: selectedSale.productSpecification.doorNotes[
                      index
                    ].important
                      ? "bold"
                      : "normal",
                    color: selectedSale.productSpecification.doorNotes[index]
                      .important
                      ? "red"
                      : "black",
                  }}
                >
                  {note.description}
                </p>
                {deletingNote ? (
                  <Spinner
                    animation="border"
                    role="status"
                    style={{ width: "15px", height: "15px" }}
                  >
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <button onClick={() => addImportant(index)}>
                      <i
                        className="fas fa-star"
                        style={{
                          fontSize: "10px",
                          color: selectedSale.productSpecification.doorNotes[
                            index
                          ].important
                            ? "red"
                            : "black",
                        }}
                      ></i>
                    </button>
                    <button onClick={(event) => moveUpNote(index)}>
                      <i className="fas fa-caret-up"></i>
                    </button>
                    <button
                      onClick={() => {
                        moveDownNote(index);
                      }}
                    >
                      <i className="fas fa-caret-down"></i>
                    </button>
                    <button onClick={() => removeNote(index)}>
                      <i className="fas fa-minus"></i>
                    </button>
                  </div>
                )}
              </li>
            );
          })}
        </ul>

        <div className="Sale-top-right-payment-container-button">
          <button
            className="btn btn-success"
            onClick={() => setShowNotesModal(true)}
          >
            Add Door Note
          </button>
        </div>
      </div>

      <DoorNoteModal
        showNotesModal={showNotesModal}
        setShowNotesModal={setShowNotesModal}
        addNote={addNote}
      />
    </div>
  );
};

export default SaleDoorRender;
