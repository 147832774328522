import React, { useContext, useState } from "react";
import Moment from "moment";

import { CustomersContext } from "../../context/customersContext";
import { SalesContext } from "../../context/salesContext";
import FormSaleDoor from "./FormSaleDoor";
import FormSaleGlass from "./FormSaleGlass";
import FormSaleWorkOrder from "./FormSaleWorkOrder";
import SaleDoorRender from "../details/SaleDoorRender";
import "./FormSale.css";
import "./FormCustomer.css";

// default empty state object
const salesField = {
  orderDate: Moment().format("YYYY-MM-DD"),
  contractor: "",
  customerId: "",
  po: "",
  product: "",
  productSpecification: {
    doorNotes: [],
    doorSystem: "",
    brickToBrick: "",
    frameSize: {
      width: "",
      height: "",
    },
    jamb: {
      jambSize: "",
      jambMaterial: "Vinyl Wrap",
    },
    sill: "",
    sillExtension: "",
    doorLeft: {
      doorMaterial: "",
      doorBrand: "",
      doorStyle: "",
      paintOut: "",
      paintIn: "",
      doorSlabWidth: "",
      doorSlabHeight: "",
      lock: {
        lockType: "Standard Double Bore",
        distanceFromTop: '40-3/8"',
        lockStyle: "",
      },
      hinge: {
        position: "",
        hingeColour: "",
        selfClosing: "No",
      },
      doorInsert: {
        doorInsert: "",
        design: "",
        width: "",
        height: "",
        quantity: "",
        position: "",
      },
      open: "",
    },
    doorMiddle: {
      doorMaterial: "",
      doorBrand: "",
      doorStyle: "",
      paintOut: "",
      paintIn: "",
      doorSlabWidth: "",
      doorSlabHeight: "",
      lock: {
        lockType: "Standard Double Bore",
        distanceFromTop: '40-3/8"',
        lockStyle: "",
      },
      hinge: {
        position: "",
        hingeColour: "",
        selfClosing: "",
      },
      doorInsert: {
        doorInsert: "",
        design: "",
        width: "",
        height: "",
        quantity: "",
        position: "",
      },
      open: "",
    },
    doorRight: {
      doorMaterial: "",
      doorBrand: "",
      doorStyle: "",
      paintOut: "",
      paintIn: "",
      doorSlabWidth: "",
      doorSlabHeight: "",
      lock: {
        lockType: "Standard Double Bore",
        distanceFromTop: '40-3/8"',
        lockStyle: "",
      },
      hinge: {
        position: "",
        hingeColour: "",
        selfClosing: "",
      },
      doorInsert: {
        doorInsert: "",
        design: "",
        width: "",
        height: "",
        quantity: "",
        position: "",
      },
      open: "",
    },
    sidelightLeft: {
      sidelight: "No",
      panel: "",
      panelWidth: "",
      panelHeight: "",
      panelWidth2: "",
      panelHeight2: "",
      insert: "",
      insertDesign: "",
      insertWidth: "",
      insertHeight: "",
    },
    sidelightRight: {
      sidelight: "No",
      panel: "",
      panelWidth: "",
      panelHeight: "",
      panelWidth2: "",
      panelHeight2: "",
      insert: "",
      insertDesign: "",
      insertWidth: "",
      insertHeight: "",
    },
    transom: {
      transomBox: "No",
      transomType: "",
      transomInsertDesign: "",
      transomArcHeight: "",
      transomArcLegs: "",
      transomRow: "",
      transomColumn: "",
      transomInsertWidth: "",
      transomInsertHeight: "",
    },
  },
  paymentMethod: "",
  cost: "",
  labourCost: "",
  salesPrice: "",
  payments: [],
  notes: [],
  workOrder: {
    installationRequired: "",
    status: "Processed",
  },
  expectedCompletionDate: "",
  completionDate: "",
  recentActivities: [],
};

const width10 = {
  width: "10%",
};

const width20 = {
  width: "20%",
};

const width30 = {
  width: "30%",
};

const FormSale = (props) => {
  const { action } = useContext(SalesContext);
  const { customers } = useContext(CustomersContext);
  const [saleAdded, setSaleAdded] = useState(false);
  const [showDoorRender, setShowDoorRender] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [renderValidations, setRenderValidations] = useState([]);

  const [newSale, setNewSale] = useState(salesField);

  const [payment, setPayment] = useState({
    date: Moment(),
    amount: "",
  });

  const [note, setNote] = useState({
    date: Moment(),
    description: "",
  });

  // UPDATE STATE FROM INPUT FIELD
  const handleUpdate = (set, field, value) => {
    if (field === "expectedCompletionDate") {
      if (
        newSale.orderDate === value &&
        newSale.workOrder.installationRequired === "No"
      ) {
        setNewSale({
          ...newSale,
          completionDate: Moment().format("LL"),
          workOrder: {
            ...newSale.workOrder,
            status: "Completed",
          },
        });
      } else {
        setNewSale({
          ...newSale,
          completionDate: "",
          workOrder: {
            ...newSale.workOrder,
            status: "Processed",
          },
        });
      }
    }

    // Set contractor to customer if contractor is same as customer; else set field
    if (field === "customerId") {
      customers.map((customer) => {
        if (customer._id === value && customer.contractor === "Yes") {
          set((state) => ({
            ...state,
            [field]: value,
            contractor: value,
          }));
        } else {
          set((state) => ({
            ...state,
            [field]: value,
          }));
        }
      });
    } else {
      set((state) => ({
        ...state,
        [field]: value,
      }));
    }
  };

  // SUBMIT NEW SALE
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Form Validation
    if (
      newSale.orderDate === "" ||
      newSale.po === "" ||
      newSale.customerId === "" ||
      newSale.product === "" ||
      newSale.workOrder.installationRequired === "" ||
      newSale.expectedCompletionDate === "" ||
      newSale.salesPrice === "" ||
      newSale.paymentMethod === ""
    ) {
      let validations = [];
      Object.entries(newSale).map((sale) => {
        if (
          ((sale[0] === "orderDate" ||
            sale[0] === "po" ||
            sale[0] === "customerId" ||
            sale[0] === "product" ||
            sale[0] === "expectedCompletionDate" ||
            sale[0] === "salesPrice" ||
            sale[0] === "paymentMethod") &&
            sale[1] === "") ||
          sale[1].installationRequired === "" ||
          (sale[1].doorInsert && sale[1].doorInsert.doorInsert === "") ||
          (sale[1].sidelightLeft && sale[1].sidelightLeft.sidelight === "") ||
          (sale[1].sidelightRight && sale[1].sidelightRight.sidelight === "") ||
          (sale[1].transom && sale[1].transom.transomBox === "")
        ) {
          if (sale[1].doorInsert && sale[1].doorInsert.doorInsert === "") {
            validations.push("doorInsert");
          }

          if (sale[1].sidelightLeft && sale[1].sidelightLeft.sidelight === "") {
            validations.push("sidelightLeft");
          }

          if (
            sale[1].sidelightRight &&
            sale[1].sidelightRight.sidelight === ""
          ) {
            validations.push("sidelightRight");
          }

          if (sale[1].transom && sale[1].transom.transomBox === "") {
            validations.push("transom");
          }
          validations.push(sale[0]);
        }
      });

      setValidationErrors(validations);
    } else {
      // Post request to add sale
      try {
        if (payment.amount) {
          newSale.payments.push(payment);
        }
        if (note.description) {
          newSale.notes.push(note);
        }

        // SET DEFAULT VALUE IF BLANK
        if (newSale.productSpecification.doorLeft.paintOut === "") {
          newSale.productSpecification.doorLeft.paintOut = "Factory White";
        }
        if (newSale.productSpecification.doorMiddle.paintOut === "") {
          newSale.productSpecification.doorMiddle.paintOut = "Factory White";
        }
        if (newSale.productSpecification.doorRight.paintOut === "") {
          newSale.productSpecification.doorRight.paintOut = "Factory White";
        }

        if (newSale.productSpecification.doorLeft.paintIn === "") {
          newSale.productSpecification.doorLeft.paintIn = "Factory White";
        }
        if (newSale.productSpecification.doorMiddle.paintIn === "") {
          newSale.productSpecification.doorMiddle.paintIn = "Factory White";
        }
        if (newSale.productSpecification.doorRight.paintIn === "") {
          newSale.productSpecification.doorRight.paintIn = "Factory White";
        }

        if (newSale.productSpecification.doorLeft.doorInsert.position === "") {
          newSale.productSpecification.doorLeft.doorInsert.position = "Center";
        }
        if (
          newSale.productSpecification.doorMiddle.doorInsert.position === ""
        ) {
          newSale.productSpecification.doorMiddle.doorInsert.position =
            "Center";
        }
        if (newSale.productSpecification.doorRight.doorInsert.position === "") {
          newSale.productSpecification.doorRight.doorInsert.position = "Center";
        }

        if (
          newSale.productSpecification.doorLeft.doorInsert.design !== "" &&
          newSale.productSpecification.doorLeft.doorInsert.doorInsert === ""
        ) {
          newSale.productSpecification.doorLeft.doorInsert.doorInsert = "Yes";
        }
        if (
          newSale.productSpecification.doorMiddle.doorInsert.design !== "" &&
          newSale.productSpecification.doorMiddle.doorInsert.doorInsert === ""
        ) {
          newSale.productSpecification.doorMiddle.doorInsert.doorInsert = "Yes";
        }
        if (
          newSale.productSpecification.doorRight.doorInsert.design !== "" &&
          newSale.productSpecification.doorRight.doorInsert.doorInsert === ""
        ) {
          newSale.productSpecification.doorRight.doorInsert.doorInsert = "Yes";
        }
        if (newSale.productSpecification.transom.transomRow === "") {
          newSale.productSpecification.transom.transomRow = 1;
        }
        if (newSale.productSpecification.transom.transomColumn === "") {
          newSale.productSpecification.transom.transomColumn = 1;
        }

        await action.addSale(newSale);
        setSaleAdded(true);

        setPayment({
          date: Moment(),
          amount: "",
        });

        setNote({
          date: Moment(),
          description: "",
        });
        setNewSale(salesField);
        salesField.payments = [];
        salesField.notes = [];
      } catch (error) {
        console.log("Error: ", error);
      }
    }
  };

  // SET PRODUCTS FIELD OF STATE
  const selectProduct = (event) => {
    event.preventDefault();

    // Set glassInsert Field based on selection
    if (event.target.innerHTML === "Glass") {
      if (newSale.productSpecification.doorLeft.doorInsert !== "Yes") {
        setNewSale({
          ...newSale,
          product: event.target.innerHTML,
          productSpecification: {
            ...newSale.productSpecification,
            doorLeft: {
              ...newSale.productSpecification.door,
              doorInsert: {
                ...newSale.productSpecification.doorLeft.doorInsert,
                doorInsert: "Yes",
              },
            },
          },
        });
      }
    } else {
      setNewSale({
        ...newSale,
        product: event.target.innerHTML,
      });
    }
  };

  // RENDOR DOOR IMAGE
  const renderDoor = (event) => {
    event.preventDefault();
    let validations = [];

    if (
      newSale.productSpecification.frameSize.width === "" ||
      newSale.productSpecification.frameSize.height === "" ||
      newSale.productSpecification.doorLeft.doorSlabHeight === "" ||
      newSale.productSpecification.doorLeft.doorSlabWidth === ""
    ) {
      Object.entries(newSale).map((sale) => {
        if (
          (sale[1].frameSize && sale[1].frameSize.height === "") ||
          (sale[1].frameSize && sale[1].frameSize.width === "")
        ) {
          validations.push("Frame size");
        }

        if (
          (sale[1].door && sale[1].doorLeft.doorSlabWidth === "") ||
          (sale[1].door && sale[1].doorLeft.doorSlabHeight === "")
        ) {
          validations.push("Door slab size");
        }
      });

      setShowDoorRender(false);
    } else {
      setShowDoorRender(true);
    }

    setRenderValidations(validations);
  };

  const inputField = (labelName, field, value, type) => {
    return (
      <div className="Form-section-1-input">
        <div className="Form-input-container">
          <span className="Form-label">{labelName}</span>
          <input
            className="Form-input"
            type={type}
            name={field}
            value={value}
            onChange={(event) =>
              handleUpdate(setNewSale, field, event.target.value)
            }
            autoFocus={true}
          />
          <span className="Form-input-focus"></span>
        </div>
        <span className="Form-error-message">
          {validationErrors.length > 0 &&
            validationErrors.includes(field) &&
            "Required"}
        </span>
      </div>
    );
  };

  // RENDER CONDITIONAL FORM
  const productForm = () => {
    if (newSale.product === "Door") {
      return (
        <div>
          <FormSaleDoor
            newSale={newSale}
            setNewSale={setNewSale}
            handleUpdate={handleUpdate}
            renderDoor={renderDoor}
            showDoorRender={showDoorRender}
            validationErrors={validationErrors}
            renderValidations={renderValidations}
          />
          {showDoorRender && (
            <SaleDoorRender newSale={newSale} setNewSale={setNewSale} />
          )}
        </div>
      );
    } else if (newSale.product === "Glass") {
      return (
        // Return form for glass insert sale
        <FormSaleGlass
          newSale={newSale}
          setNewSale={setNewSale}
          handleUpdate={handleUpdate}
        />
      );
    } else if (newSale.product !== "") {
      return (
        <div>
          <div className="Form-section-1-input" style={width20}>
            <div className="Form-input-container">
              <span className="Form-label">Product</span>
              <select
                className="Form-input"
                name="product"
                value={newSale.product}
                onChange={(event) =>
                  handleUpdate(setNewSale, "product", event.target.value)
                }
              >
                <option>Select</option>
                <option value="Door Slab">Door Slab</option>
                <option value="Door Lock">Door Lock</option>
                <option value="Sill">Sill</option>
                <option value="Other">Other</option>
              </select>
              <span className="Form-input-focus"></span>
            </div>

            {inputField("Cost", "cost", newSale.cost, "number")}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="FormSale">
      {/* DISPLAY SUCCESS MESSAGE */}
      {saleAdded && (
        <button onClick={() => setSaleAdded(false)}>
          <div className="FormCustomer-successful-message">
            <i className="fas fa-thumbs-up"></i>
            <p>Sales Record Successfully Added!</p>
            <div className="FormCustomer-success-message-btn">
              <button
                className="btn btn-success"
                onClick={() => setSaleAdded(false)}
              >
                Record Another Sales Transaction
              </button>
              {/* CANNOT GET BUTTON TO CLOSE MODAL */}
              {/* <button
                className="btn btn-danger"
                onClick={() => props.closeModal}
              >
                Close
              </button> */}
            </div>
          </div>
        </button>
      )}

      {/* DISPLAY VALIDATION ERRORS */}
      {validationErrors.length > 0 && (
        <div>
          <p style={{ color: "red", fontWeight: "bold" }}>
            *Please fill out the required fields
          </p>
        </div>
      )}

      <form>
        <h4 className="title">Record Sales</h4>
        <div className="Form-section-1">
          {inputField("Order Date", "orderDate", newSale.orderDate, "date")}

          <div className="Form-section-1-input" style={width30}>
            <div className="Form-input-container">
              <span className="Form-label">PO #</span>
              <input
                className="Form-input"
                type="text"
                name="po"
                value={newSale.po}
                onChange={(event) =>
                  handleUpdate(setNewSale, "po", event.target.value)
                }
              />
              <span className="Form-input-focus"></span>
            </div>
            <span className="Form-error-message">
              {validationErrors.length > 0 &&
                validationErrors.includes("po") &&
                "Required"}
            </span>
          </div>

          <div className="Form-section-1-input">
            <div className="Form-input-container">
              <span className="Form-label">Customer</span>
              <select
                className="Form-input"
                // list="customers"
                name="customerId"
                value={newSale.customerId}
                onChange={(event) =>
                  handleUpdate(setNewSale, "customerId", event.target.value)
                }
                placeholder="Select"
              >
                <option value="">Select</option>
                {customers.map((customer, index) => {
                  return (
                    <option key={index} value={customer._id}>
                      {customer.firstName} {customer.lastName}{" "}
                      {customer.mobile && `(${customer.mobile})`}
                    </option>
                  );
                })}
              </select>
              <span className="Form-input-focus"></span>
              <datalist id="customers">
                {customers.map((customer, index) => {
                  return (
                    <option
                      key={index}
                      value={`${customer.firstName} ${customer.lastName}`}
                    >
                      {customer.mobile}
                    </option>
                  );
                })}
              </datalist>
            </div>
            <span className="Form-error-message">
              {validationErrors.length > 0 &&
                validationErrors.includes("customerId") &&
                "Required"}
            </span>
          </div>

          <div className="Form-section-1-input">
            <div
              className="Form-input-container"
              data-validate="Valid email is required: ex@abc.xyz"
            >
              <span className="Form-label">Contractor</span>
              <select
                className="Form-input"
                // list="customers"
                name="contractor"
                value={newSale.contractor}
                onChange={(event) =>
                  handleUpdate(setNewSale, "contractor", event.target.value)
                }
                placeholder="Select"
              >
                <option value="">Select</option>
                {customers.map((customer, index) => {
                  if (customer.contractor === "Yes") {
                    return (
                      <option key={index} value={customer._id}>
                        {customer.firstName} {customer.lastName}{" "}
                        {customer.mobile && `(${customer.mobile})`}
                      </option>
                    );
                  }
                })}
              </select>
              <span className="Form-input-focus"></span>
              <datalist id="customers">
                {customers.map((customer, index) => {
                  return (
                    <option
                      key={index}
                      value={`${customer.firstName} ${customer.lastName}`}
                    >
                      {customer.mobile}
                    </option>
                  );
                })}
              </datalist>
            </div>
          </div>
        </div>

        <div className="Form-section-1-input">
          <div
            className="Form-input-container"
            data-validate="Message is required"
          >
            <span className="Form-label">Select a Product:</span>
            <div className="FormSale-category">
              <button
                className={`FormSale-category-card ${
                  newSale.product === "Door" && "product-selected"
                }`}
                onClick={(event) => selectProduct(event)}
              >
                Door
              </button>
              <button
                className={`FormSale-category-card ${
                  newSale.product === "Glass" && "product-selected"
                }`}
                onClick={(event) => selectProduct(event)}
              >
                Glass
              </button>
              <button
                className={`FormSale-category-card ${
                  newSale.product === "Other" && "product-selected"
                }`}
                onClick={(event) => selectProduct(event)}
              >
                Other
              </button>
            </div>
          </div>
          <span className="Form-error-message">
            {validationErrors.length > 0 &&
              validationErrors.includes("product") &&
              "Required"}
          </span>
        </div>
        {productForm()}

        {/* WORK ORDER FIELDS */}
        {newSale.product !== "" && (
          <FormSaleWorkOrder
            newSale={newSale}
            setNewSale={setNewSale}
            handleUpdate={handleUpdate}
            validationErrors={validationErrors}
          />
        )}
        <div className="Form-section-1-row" style={width20}>
          {inputField(
            "Expected Completion Date",
            "expectedCompletionDate",
            newSale.expectedCompletionDate,
            "date"
          )}
        </div>

        {newSale.product !== "" && (
          <div>
            <h6 style={{ fontWeight: 700 }}>Payment</h6>
            <div className="Form-section-1">
              <div className="Form-section-1-input" style={width10}>
                <div
                  className="Form-input-container"
                  style={{ marginBottom: "2rem" }}
                >
                  <span className="Form-label">Cost</span>
                  <input
                    className="Form-input"
                    type="number"
                    name="cost"
                    value={newSale.cost}
                    onChange={(event) =>
                      handleUpdate(setNewSale, "cost", event.target.value)
                    }
                  />
                  <span className="Form-input-focus"></span>
                </div>
              </div>

              <div className="Form-section-1-input" style={width10}>
                <div className="Form-input-container">
                  <span className="Form-label">Sales Price</span>
                  <input
                    className="Form-input"
                    type="number"
                    name="salesPrice"
                    value={newSale.salesPrice}
                    onChange={(event) =>
                      handleUpdate(setNewSale, "salesPrice", event.target.value)
                    }
                  />
                  <span className="Form-input-focus"></span>
                </div>
              </div>

              <div className="Form-section-1-input" style={width10}>
                <div className="Form-input-container">
                  <span className="Form-label">Payment Method</span>
                  <select
                    className="Form-input"
                    name="paymentMethod"
                    value={newSale.paymentMethod}
                    onChange={(event) =>
                      handleUpdate(
                        setNewSale,
                        "paymentMethod",
                        event.target.value
                      )
                    }
                  >
                    <option value="">Select</option>
                    <option value="Cash">Cash</option>
                    <option value="Cheque">Cheque</option>
                    <option value="Credit Card">Credit Card</option>
                    <option value="Debit">Debit</option>
                    <option value="E-Transfer">E-Transfer</option>
                  </select>
                  <span className="Form-input-focus"></span>
                </div>
                <span className="Form-error-message">
                  {validationErrors.length > 0 &&
                    validationErrors.includes("paymentMethod") &&
                    "Required"}
                </span>
              </div>
            </div>

            <div className="Form-section-1-input" style={width10}>
              <div className="Form-input-container">
                <span className="Form-label">Amount Paid</span>
                <input
                  className="Form-input"
                  type="number"
                  name="amount"
                  value={payment.amount}
                  onChange={(event) =>
                    setPayment({ ...payment, amount: event.target.value })
                  }
                />
                <span className="Form-input-focus"></span>
              </div>
            </div>

            <div className="Form-section-1-input">
              <div
                className="Form-input-container"
                data-validate="Message is required"
              >
                <span className="Form-label">Notes</span>
                <textarea
                  className="Form-input"
                  name="notes"
                  value={note.description}
                  onChange={(event) =>
                    setNote({ ...note, description: event.target.value })
                  }
                  rows="5"
                ></textarea>
                <span className="Form-input-focus"></span>
              </div>
            </div>
          </div>
        )}

        <div className="Form-add-button">
          <button className="btn btn-success" onClick={handleSubmit}>
            Add
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormSale;
